/* eslint-disable no-undef */

/* eslint-disable no-console */
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode,
} from '@angular/common/http';

import { SpinnerService } from '@app/libs/spinner';

import { IErrorResponse } from '../../interfaces/error-response.interface';
import { SnackbarService } from '@app/libs/snackbar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _snackbarService: SnackbarService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this._spinnerService.stop();
        if(request.method == 'GET'){
          if(error.status !== 404){
            this._snackbarService.error(`Failed to get data. Invalid request`);
          }
        }
        if(request.method == 'POST' || request.method == 'PUT' ){
          this._snackbarService.error("Save operation failed");
        }
        if(request.method == 'DELETE'){
          this._snackbarService.error("Delete failed");
        }

        if (error.status !== HttpStatusCode.InternalServerError) {
          this._checkError(error);

          return throwError(error);
        }

        return of(error.error);
      }),
    );
  }

  private _onNotFoundError(url: string | null): void {
    console.warn('Not found error', url);
  }

  private _onInternalServerError(error: IErrorResponse): void {
    console.error('Internal server error: ', error);
  }

  private _onUnknownError(url: string | null): void {
    console.warn('Unknown Error: ', url);
  }

  private _onBadRequestError(error: IErrorResponse): void {
    console.error('Bad request error: ', error);
  }

  private _checkError(error: HttpErrorResponse): void {
    const url = error.url;

    switch (error.status) {
      case HttpStatusCode.InternalServerError:
        this._onInternalServerError(error.error);
        break;
      case HttpStatusCode.BadRequest:
        this._onBadRequestError(error.error);
        break;
      case HttpStatusCode.NotFound:
        this._onNotFoundError(url);
        break;
      default:
        this._onUnknownError(url);
    }
  }
}
