import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { TypeLang } from '../../conts';
import { APP_VERSION } from '@lib/app-tokens';
import { APP_ASSETS_URL } from '@lib/window';

@Injectable({
  providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {

  constructor(
    private readonly _http: HttpClient,
    @Inject(APP_VERSION) private readonly appVersion: string,
    @Inject(APP_ASSETS_URL) private readonly assets: string,
  ) {}

  public getTranslation(lang: TypeLang): Observable<Translation> {
    return this._http.get<Translation>(`${this.assets}/i18n/${lang}.json?${this.appVersion}`);
  }
}
