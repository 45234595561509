export enum FiscalTypeEnum {
  network = 'network',
  online = 'online',
}

export enum PaymentMethods {
  CASH = 'cash',
  APPLE_PAY = 'applePay',
  CREDIT_CARD = 'creditCard',
  GOOGLE_PAY = 'googlePay',
  BLIK = 'blik',
  CARD_ON_DELIVERY = 'cardOnDelivery',
}

export enum ProductTypes {
  DISH = 'dish',
  MODIFIER = 'modifier',
  CHILD_PRODUCT = 'child-product',
}
