import { Observable } from 'rxjs';
import { Component, inject } from '@angular/core';
import { SpinnerService } from './services/spinner.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  private debounceTime = 500; //todo: env
  private readonly _spinnerService: SpinnerService = inject(SpinnerService);
  public active$: Observable<boolean> = this._spinnerService.active$.pipe(debounceTime(this.debounceTime));

  constructor() {}
}
