import { inject, Injectable } from '@angular/core';
import { Repository, ContextOptions, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import { Observable } from 'rxjs';
import { API_GATEWAY_URL, TypeResponseData } from '@app/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ImageRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/pim/storage';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
    protected readonly httpClient: HttpClient,
  ) {
    super();
  }

  //[USE] POST   /v1/admin/images Загрузка изображения [DOC]
  public uploadImage(image: File): Observable<TypeResponseData<{ s3Key: string }>> {
    const formData = new FormData();
    formData.append('imagePath', image);
    return this.http.post<TypeResponseData<{ s3Key: string }>, FormData>(
      this.pathConstruct('upload-pre-sign'),
      formData, {context: this.setContextOptions({...this.contextOptions, withoutCache: true})}
    );
  }

  //[NOT] DELETE /v1/admin/images/{s3Key} Удаление изображения [DOC]
  public deleteImage(s3Key: string): Observable<never> {
    return this.delete<never>(`images/${s3Key}`);
  }

  async preSignUploadFile(file: File): Promise<{ title: string, path: string, url: string }> {
    const { type, name } = file;

    const preSignResponse = await this.httpClient.post<any>(this.apiGatewayUrl + '/v1/admin/pim/storage/upload-pre-sign', {
      contentType: type,
      extension: name.split(".").pop()
    }).toPromise();

    const { url, path } = preSignResponse;

    await this.uploadFileToPresignedUrl(url, file, type);

    return {
      title: name,
      path: path,
      url: url
    };
  }

  private uploadFileToPresignedUrl(url: string, file: File, type: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', type);

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentCompleted = Math.round((e.loaded * 100) / e.total);
          console.debug(`Upload "${file.name}" progress = ${percentCompleted}`);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject('Upload failed');
        }
      };

      xhr.onerror = () => {
        reject('Upload failed');
      };

      xhr.send(file);
    });
  }
  
}