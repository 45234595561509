import {
  EntityBase,
  IModifier,
  IProduct,
  PaymentMethods,
  TypeCourier,
  TypeCustomerData,
  TypeVenue,
  TypeLocation
} from '@app/shared';

export enum DeliveryMethods {
  TAXI = 'taxi',
  COURIER = 'courier',
  PICKUP = 'pickup',
  DINE_IN = 'dine-in',
  AGGREGATOR_COURIER = 'aggregator-courier',
}

export enum DeliveryStatuses {
  RETURNED = 'returned',
  DELIVERED = 'delivered',
}

export enum DeliveryProviders {
  INTERNAL = 'internal',
  WOLTDRIVE = 'wolt-drive',
}

export enum SalesProviders {
  GLOVO = 'glovo',
  BOLT = 'bolt',
  WOLT = 'wolt',
  UBER = 'uber',
  UPMENU = 'upmenu',
}

export enum OrderStatuses {
  ALL = 'all',
  NEW = 'new',
  PAYMENT_AWAITING = 'payment_awaiting',
  CONFIRMATION_AWAITING = 'confirmation_awaiting',
  IN_PROGRESS = 'in_progress',
  READY_FOR_DELIVERY = 'ready_for_delivery',
  IN_DELIVERY = 'in_delivery',
  CANCELLED = 'cancelled',
  DELIVERED = 'delivered',
  INRETURN = 'in_return',
  RETURNED = 'returned',
  FITTING = 'fitting'
}

export type TypeOrderProduct = {
  position: number
  name?: string,
  productId: string,
  price: number,
  priceWithDiscount: number,
  quantity: number,
  id: string,
  status: string,
  discount: number,
  productData: IProduct,
  modifiers: Array<IModifier>,
};

export type TypeDeliveryAddress = {
  addressFirst: string,
  comment: string,
  kitchenId: string,
}

export interface IItemsProduct {
  status: string,
  id: string
}
export enum ItemsStatuses {
  CANCEL = "cancel",
  KEEP = "keep"
}

export type TypeOrder = EntityBase & {
  number: string,
  channel: string,
  status: string,
  deliveryStatus: string,
  createdAt: Date,
  finishedAt: Date,
  customerData?: TypeCustomerData,
  courierData?: TypeCourier,
  courierInfo?: TypeCourier,
  deliveryAddressData: TypeDeliveryAddress,
  deliveryAddressCoordinates: TypeLocation,
  deliveryAddressString: string,
  deliveryProvider: DeliveryProviders,
  cookingTime: number,
  deliveryTime: number,
  scheduledCookingTime: Date,
  scheduledDeliveryTime: Date,
  items: Array<TypeOrderProduct>,
  promoCode: string,
  total: number,
  totalWithDiscount: number,
  totalItemsPrice: number,
  currency: string,
  paymentMethod: PaymentMethods,
  deliveryMethod: DeliveryMethods,
  courierId?: string,
  appliedCashback: number,
  cutlery: number,
  venueId: string,
  venue: TypeVenue,
  aggregatorCode: string,
  comment: string,
  cuisineId: string,
  taxNumber?: string,
  deliveryFee?: string,
  surcharge?: string,
  discount?: string,
  cancelReason?: string,
  taxReceiptLink?: string
}

export type TypeOrderTransition = {
  fromPlace: OrderStatuses,
  toPlace?: OrderStatuses | null,
  createdAt: Date,
  userName: string,
  diff?: string | null,
}
