import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, StaticProvider } from '@angular/core';
import { Router } from '@angular/router';
import { extractError } from '@lib/error-handler/extract-error';
import { Breadcrumbs, BrowserTracing, ErrorHandlerOptions, init, Replay, routingInstrumentation, SentryErrorHandler, TraceService } from '@sentry/angular-ivy';
import { Configuration } from '@app/shared';

export class AppSentryErrorHandler extends SentryErrorHandler {
  excludeError(error: unknown): boolean {
    return error instanceof HttpErrorResponse && [HttpStatusCode.NotFound, HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)
  }

  handleError(error: unknown): void {
    if (this.excludeError(error)) {
      if (this._options.logErrors) {
        console.error(error);
      }
      return;
    }
    return super.handleError(error);
  };

  constructor(options?: ErrorHandlerOptions){
    super(options);
  }
}

export let SentryProviders: Array<StaticProvider> = [
  {
    provide: ErrorHandler,
    useValue: new AppSentryErrorHandler({
      showDialog: false,
      extractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
        return defaultExtractor(extractError(error));
      }
    }),
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => Promise.resolve(),
    deps: [TraceService],
    multi: true,
  }
];

export function initSentryBrowser(environment: Configuration){

  const {enable, dsn, breadcrumbs = {console: false}} = environment?.sentry?.browser || {};
  const {app: {version: release}, env: {name: envName} } = environment || {};
  const enabled = dsn && enable;
  const tracingOrigins = [window?.location?.hostname ?? /^\//];
  const tracePropagationTargets = [new URL(environment.gateway.browser.origin)?.hostname ?? /^\//];

  if (enabled) {

    init({
      environment: envName,
      release: release,
      dsn: dsn,
      integrations: [
        new Breadcrumbs(breadcrumbs),
        new BrowserTracing({
          tracingOrigins: tracingOrigins,
          tracePropagationTargets: tracePropagationTargets,
          routingInstrumentation: routingInstrumentation
        }),
        new Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  } else {
    console.warn('sentry disabled');
    SentryProviders = [];
  }
}
