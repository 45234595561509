export function normalizeDate(date: Date): string {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const finalDay = day < 10 ? `0${day}` : day;
  const finalMonth = month < 10 ? `0${month}` : month;

  return `${year}-${finalMonth}-${finalDay}`;
}
