import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import {OrganizationStorage, PUSHER_SUPER_CHANNEL } from '@app/shared';
import {AuthService, matchRoles, TypeAuthRoles, AuthRoles} from "@app/auth";
@Injectable({
  providedIn: 'root'
})
export class PusherChannelResolver {
  private readonly userRolesSubject: BehaviorSubject<TypeAuthRoles> = this._authService.currentUserRolesSubject;

  constructor(
    private readonly organizationStorage: OrganizationStorage,
    private readonly _authService: AuthService,
    @Inject(PUSHER_SUPER_CHANNEL) private readonly superChannel: string
  ) {
  }

  public getChannelName(resource: string): string {
    let userRoles = this.userRolesSubject.getValue();
    if (matchRoles(userRoles, [AuthRoles.SUPER_ADMIN])) {
      return `${resource}.super.${this.superChannel}`;
    }

    return `${resource}.partner.${this.organizationStorage.partnerId}`;
  }
}
