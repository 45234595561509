import { BehaviorSubject, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private _active$ = new BehaviorSubject<boolean>(false);
  private _disabled$ = new BehaviorSubject<boolean>(false);
  private _disabledUrls$ = new BehaviorSubject<Array<string|RegExp>>([]);

  public get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  public get disabled$(): Observable<boolean> {
    return this._disabled$.asObservable();
  }

  public get disabled(): boolean {
    return this._disabled$.value;
  }

  public get disabledUrls(): Array<string|RegExp> {
    return this._disabledUrls$.value;
  }

  constructor() {}

  public start(): void {
    if (!this._active$.value) {
      this._active$.next(true);
    }
  }

  public stop(): void {
    if (this._active$.value) {
      this._active$.next(false);
    }
  }

  public disable(...urls: Array<string|RegExp>): void {
    if ((urls?.length ?? 0) == 0) {
      this._disabled$.next(true);
    }
    this._disabledUrls$.next(urls);
  }

  public enable(): void {
    this._disabled$.next(false);
  }
}

type AsyncFn = (...args: unknown[]) => Promise<unknown>;

export function AsyncOverlayDecorator() {
  return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<AsyncFn>) => {
    let originalFunc: AsyncFn | undefined = descriptor.value;
    descriptor.value = async function () {
      const _spinnerService: SpinnerService = inject(SpinnerService);
      if (originalFunc) {
        _spinnerService.start();
        return await originalFunc.apply(this, Array.from(arguments))
          .then((e) => {
            _spinnerService.stop();
            return Promise.resolve(e);
          })
          .catch((e) => {
            _spinnerService.stop();
            return Promise.reject(e);
          }).finally(() => {
            _spinnerService.stop();
          });
      }
      return originalFunc;
    }
  }
}
