import { Observable } from 'rxjs';
import { HttpOptions, HttpService } from './http.service';
import { HttpContext, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { HttpRequestWithoutCacheContext } from '@app/interceptors/HttpRequestWithoutCache.interceptor';
import { HttpRequestTimeoutContext } from '@app/interceptors/HttpRequestTimeout.interceptor';

declare interface ResponseData<T> {
  data: T;
}

export type ContextOptions = {
  withoutCache: boolean, // true set header 'Cache-Control'= 'no-cache'
  requestTimeout: number, // не ждать ответа более чем n секунд
};

export type RepositoryParams = NonNullable<HttpParamsOptions['fromObject']>;

export enum RepositoryVersion {'v1' = 'v1', 'v2' = 'v2', 'v3' = 'v3'}

export abstract class Repository<T extends ResponseData<any> = ResponseData<any>> {

  protected abstract readonly version: RepositoryVersion | unknown;
  protected abstract readonly apiGatewayUrl: string;
  protected abstract readonly path: string;
  protected abstract readonly http: HttpService;

  protected matchParamsAsFilter(params: RepositoryParams, filter: object, {skipEmpty}: { skipEmpty: boolean } = {skipEmpty: false}): RepositoryParams {
    Object.entries(filter).forEach(([key, value]) => {
      if (!skipEmpty || value) {
        params[`filter[${key}]`] = String(value);
      }
    })
    return params;
  }

  protected pathConstruct(additionalPath?: string) {
    return [this.apiGatewayUrl, this.version, this.path, additionalPath].filter(Boolean).join('/');
  }

  protected readonly options: Partial<HttpOptions> = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
  };

  protected abstract contextOptions: Partial<ContextOptions>;

  protected setContextOptions(contextOptions: Partial<ContextOptions> = {}): HttpContext {
    let context = new HttpContext();
    const {
      withoutCache = false,
      requestTimeout = 0,
    } = {...(this.contextOptions || {} as Partial<ContextOptions>), ...contextOptions} as Partial<ContextOptions>;
    context = HttpRequestWithoutCacheContext.setContext(context, withoutCache);
    context = HttpRequestTimeoutContext.setContext(context, requestTimeout);
    return context;
  }

  protected get<R>(
    additionalPath: string,
    paramsObject?: RepositoryParams,
    contextOptions: Partial<ContextOptions> = {}
  ): Observable<R> {
    return this.http.get<R>(this.pathConstruct(additionalPath), {
      ...this.options,
      params: paramsObject ? new HttpParams({fromObject: paramsObject}) : {},
      context: this.setContextOptions(contextOptions),
    });
  }

  protected post<R, T extends object>(
    additionalPath: string,
    data: T,
    paramsObject?: RepositoryParams,
    contextOptions: Partial<ContextOptions> = {}
  ): Observable<R> {

    return this.http.post<R, T>(this.pathConstruct(additionalPath), data, {
      ...this.options,
      params: paramsObject ? new HttpParams({fromObject: paramsObject}) : {},
      context: this.setContextOptions({...contextOptions, withoutCache: true})
    });
  }

  protected patch<R, T extends object>(
    additionalPath: string,
    data: T,
    contextOptions: Partial<ContextOptions> = {}
  ): Observable<R> {
    return this.http.patch<R, T>(this.pathConstruct(additionalPath), data, {
      ...this.options,
      context: this.setContextOptions({...contextOptions, withoutCache: true})
    });
  }

  protected put<R, T extends object>(
    additionalPath: string,
    data: T,
    contextOptions: Partial<ContextOptions> = {}
  ): Observable<R> {
    return this.http.put<R, T>(this.pathConstruct(additionalPath), data, {
      ...this.options,
      context: this.setContextOptions({...contextOptions, withoutCache: true})
    });
  }

  protected delete<T>(
    additionalPath: string,
    contextOptions: Partial<ContextOptions> = {}
  ): Observable<T> {
    return this.http.delete<T>(this.pathConstruct(additionalPath), {
      ...this.options,
      context: this.setContextOptions({...contextOptions, withoutCache: true})
    });
  }
}
