import {
  EntityNamed,
  TypeLocation,
  TypePolygonGroup,
  TypePropEnabled
} from '@app/shared';

export type TypeVenue = EntityNamed & TypePropEnabled & {
  address: string,
  addressDetails?: string,
  phoneNumber: string,
  email?: string,
  image: string,
  legalEntityName: string,
  iban: string,
  location: TypeLocation,
  notificationChannels: Array<VenueNotificationChannels>,
  polygonGroups: Array<TypePolygonGroup>,
  openingTimes: Array<TypeVenueSchedule>,
};

export type TypeVenueSchedule = {
  weekDay: number,
  openingTime: string,
  closingTime: string,
  checked?: boolean,
}

export enum VenueNotificationChannels {
  SMS = 'sms',
  EMAIL = 'email',
  PUSH = 'push',
}
