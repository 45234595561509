import {
  EntityBase,
  EntityNamed,
  ID,
  TypeProduct,
  TypeResponseFilters, TypeResponsePostSortableFilters,
  TypeResponseSortableFilters
} from '@app/shared';


export type TypeDiscount = EntityNamed & {
  isEnabled: boolean,
  amount: string,
  startDate: string,
  endDate: string,
  productIds: Array<ID<TypeProduct>>,
  weekDays: Array<number>,
};


export type TypeReferralSettings = {
  referrerCashbackAmount: string,
  refereeCashbackAmount: string,
  minOrdersSum: string,
};

export type TypeCashbackHistoryItem = EntityBase & {
  amount: number,
  operationType: string,
  kitchenId: string,
  orderId: string,
  orderNumber: string,
  orderTotal: number,
  createdAt: Date,
};

export type TypeCashbackSettings = {
  active: boolean,
  deliveryPercent: number,
  pickupPercent: number,
};

export type TypeCashback = {
  customerId: string,
  total: number,
  amount?: number,
};


type TypeGiftProduct = EntityBase & { price: number }

export enum DiscountMethod {
  AbsoluteDiscount = 'absolute_discount',
  PercentageDiscount = 'percentage_discount',
  Gift = 'gift',
  OrderPercentageDiscount = 'order_percentage_discount',
  OrderAbsoluteDiscount = 'order_absolute_discount'
}

export type TypePromoCode = EntityNamed & {
  amount: number,
  discountMethod: DiscountMethod,
  startDate: Date,
  endDate: Date,
  isEnabled: boolean,
  applyToAll: boolean,
  codes: Array<string>,
  productIds: Array<string>,
  description: string,
  minOrderTotal: number,
  giftProduct: TypeGiftProduct,
  maxUsageAmount: number,
  maxPerCustomerUsageAmount: number,
  forNewCustomers: boolean,
}

