import { APPLICATION_TITLE, CREDENTIALS_KEY, LocalStorageService, ORGANISATION_KEY } from '@app/shared';
import { KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';

export function initializer(keycloak: KeycloakService, localStorageService: LocalStorageService, config: KeycloakOptions['config']): () => Promise<boolean> {
  keycloak.keycloakEvents$.subscribe(res => {
    // debugger;
    if (res.type === KeycloakEventType.OnAuthSuccess) {
      keycloak.getToken().then(token => {
        localStorageService.set(CREDENTIALS_KEY, { access_token: token });
      });
    } else if (res.type === KeycloakEventType.OnAuthRefreshSuccess) {
      keycloak.getToken().then(token => {
        localStorageService.set(CREDENTIALS_KEY, { access_token: token });
      });
    } else if (res.type === KeycloakEventType.OnTokenExpired) {
      keycloak.updateToken(20);
    } else if (res.type === KeycloakEventType.OnAuthRefreshError) {
      keycloak.logout();
    } else if (res.type === KeycloakEventType.OnAuthLogout) {
      localStorageService.set(ORGANISATION_KEY, null);
      localStorageService.set(APPLICATION_TITLE, null);
      localStorageService.remove(CREDENTIALS_KEY);
    } else if (res.type === KeycloakEventType.OnReady || res.type === KeycloakEventType.OnAuthError) {
      const spinner = document.getElementById('spinnerOverlay');
      spinner?.classList.add('d-none');
    }
  })
  const options: KeycloakOptions = {
    config,
    initOptions: {
      onLoad: 'check-sso',
      // enableBearerInterceptor: true,
      redirectUri: window.location.href,
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
    },
  };
  return () => keycloak.init(options);
}
