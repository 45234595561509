import { APP_INITIALIZER, Injectable } from '@angular/core';
import { Location as LocationService } from '@angular/common';
import { ActivationStart, NavigationEnd, NavigationExtras, NavigationStart, Event, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Nullable } from '@app/shared';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavRouter {

  private readonly maxCount = 10; // integer value
  private history: string[] = [];

  public get getPreviousHistoryUrl(): Nullable<string> {
    return (this.history.length > 0) ? this.history[this.history.length - 1] : null;
  }

  public navigationStart$: Observable<NavigationStart> = this._router.events.pipe(
    filter((e: Event): e is NavigationStart => e instanceof NavigationStart)
  );

  public navigationEnd$: Observable<NavigationEnd> = this._router.events.pipe(
    filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
  );

  constructor(
    private _router: Router,
    private _locationService: LocationService
  ) {
    this._router.events.pipe(tap((e) => {
      if (e instanceof NavigationEnd) {
        if (this.history.length > this.maxCount) {
          this.history = this.history.slice(this.maxCount * -1);
        }
        if (!this.history.length || (this.history.length > 0 && this.history[this.history.length - 1] !== e.urlAfterRedirects)) {
          this.history.push(e.urlAfterRedirects);
        }
      } else if (e instanceof ActivationStart){
        // console.log('ActivationStart', e.snapshot, (e.snapshot?.firstChild ?? e.snapshot)?.data || {})
        // debugger;
      }
    })).subscribe();
  }

  public navigateToPreviousUrl(): void {
    const previous = this.getPreviousHistoryUrl;
    if (previous) {
      this.history.shift();
      void this._router.navigateByUrl(previous);
      // this._locationService.back();
    } else {
      // todo: nav to parent
      void this._router.navigate(['/']);
    }
  }

  public navigate(commands: any[], extras?: NavigationExtras): void {
    void this._router.navigate(commands, extras);
  }

  public navigateQuery(extras?: NavigationExtras): void {
    void this._router.navigate([], extras);
  }
}


/* Initialize Services and/or run code on application initialization. */
export const NavRouterInitializer = {
  provide: APP_INITIALIZER, useFactory: () => () => Promise.resolve(),
  deps: [NavRouter],
  multi: true,
};
