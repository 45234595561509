import { Inject, inject, Injectable, LOCALE_ID } from '@angular/core';
import { ContextOptions, Repository, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import { API_GATEWAY_URL, TypeResponseData, TypeGeocodeAutocomplete, TypeGeocode } from '@app/shared';
import { Observable } from 'rxjs';
import { defaultIfEmpty, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeocodeRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'address';
  protected readonly contextOptions: Partial<ContextOptions> = { withoutCache: true };

  constructor(
    protected readonly http: HttpService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    super();
    this.language = locale.match(/[a-z]+/)?.shift() || '';
  }
  private language: string;

  //[USE] POST   /v1/address/autocomplete [DOC]
  public autocomplete(address: string): Observable<Array<TypeGeocodeAutocomplete>> {
    const {language} = this, data = {language, address};
    return this.post<TypeResponseData<Array<TypeGeocodeAutocomplete>>, { language: string, address: string }>('autocomplete', data).pipe(
      pluck('data'),
      defaultIfEmpty(<Array<TypeGeocodeAutocomplete>>[])
    );
  }

  //[USE] POST   /v1/address/geocode [DOC]
  public getAddresses(id: string): Observable<TypeGeocode> {
    const { language } = this;
    return this.post<TypeResponseData<TypeGeocode>, { id: string, language: string }>('geocode', {id, language}).pipe(
      pluck('data'),
      defaultIfEmpty(<TypeGeocode>{})
    );
  }
}
