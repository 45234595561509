import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { APP_HOST_URL } from '@lib/window';
import { OrganizationStorage } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly _organizationStorage: OrganizationStorage,
    @Inject(APP_HOST_URL) protected readonly _appHostUrl: string,
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticated) {
      this._organizationStorage.clear();
      await this.keycloak.login({redirectUri: `${this._appHostUrl}${state.url}`});
    }
    return this.authenticated;
  }
}
