import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { ApiGatewayInterceptor } from './api-gateway.interceptor';
import { LocaleService } from '@shared/services/locale.service';
import { Nullable, OrganizationStorage } from '@app/shared';

@Injectable({
  providedIn: 'root'
})

export class LocaleHeadersInterceptor extends ApiGatewayInterceptor implements ApiGatewayInterceptor {
  constructor(
    private readonly _organizationStorage: OrganizationStorage,
    private readonly _localeService: LocaleService,
  ) {
    super();
  }

  protected _intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const partnerId: Nullable<string> = this._organizationStorage.partnerId;    
    const superAdminPartnerId: Nullable<string> = this._organizationStorage.superAdminPartnerId;    
    const lang = this._localeService.lang;
    if (lang) {
      request = request.clone({headers: request.headers.set('X-Context-Language', lang)});
    }
    if (partnerId) {
      request = request.clone({headers: request.headers.set('X-Context-Partner', partnerId)});
    }
    if (superAdminPartnerId) {
      request = request.clone({headers: request.headers.set('X-Context-Partner', superAdminPartnerId)});
    }
    return next.handle(request);
  }
}
