import { inject, Injectable } from '@angular/core';
import { LocalStorageService, Nullable } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class OrganizationStorage {
  private _partnerId = '';
  private _superAdminPartnerId: string | null = '';

  private idKey = 'organization';
  private titleKey = 'application-title';
  private _localStorageService = inject(LocalStorageService);

  public clear(): void {
    this._localStorageService.set(this.idKey, null);
    this._localStorageService.set(this.titleKey, null);
  }

  public get orgId(): Nullable<string> {
    return this._localStorageService.get<string>(this.idKey) || '';
  }

  public set orgId(value: Nullable<string>) {
    this._localStorageService.set(this.idKey, value);
  }

  public get orgTitle(): string {
    return this._localStorageService.get<string>(this.titleKey) || '';
  }

  public set orgTitle(value: Nullable<string>) {
    this._localStorageService.set(this.titleKey, value);
  }

  public get partnerId(): string {
    return this._partnerId;
  }

  public set partnerId(value: string) {
    this._partnerId = value;
  }

  public get superAdminPartnerId(): string | null {
    return this._superAdminPartnerId;
  }

  public set superAdminPartnerId(value: string | null) {
    this._superAdminPartnerId = value;
  }
}
