import { ValidationErrors, ValidatorFn } from '@angular/forms';

export function CashbackValidator(): ValidatorFn {
  return (control): ValidationErrors | null => {
    return !isNaN(control.value)
      ? null
      : {
          cashbackShouldBeNumber: { value: control.value as string },
        };
  };
}
