import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILayoutTab } from './layout-tab.interface';
import { SideMenuService } from '@shared/services/side-menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  @Input()
  public tabs: Array<ILayoutTab> | null = [];
  @Input()
  public title!: string;
  @Input()
  public backArrowUrl!: string;
  @Input()
  public direction: 'left' | 'right' | 'center' | 'end' = 'center';
  @Input()
  public buttonStyle: boolean = false;

  constructor(
    public sideService: SideMenuService,
    private route: Router,
  ) {}

  navigateToPrevious(): void{
    this.route.navigate([this.backArrowUrl]);
  }
  public toggle() {
    this.sideService.isExpanded$.next(!this.sideService.isExpanded$.value);
  }
}
