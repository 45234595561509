import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import {
  API_GATEWAY_URL,
  OrganizationStorage,
  TypePartner,
  TypeResponseData
} from '@app/shared';
import { firstValueFrom, map, Observable, pluck } from 'rxjs';
import { IPartnerUser } from '@app/partners/interfaces/partners.interface';

@Injectable({
  providedIn: 'root'
})
export class PartnerRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/partner';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
    private readonly _organizationStorage: OrganizationStorage,
  ) {
    super();
  }

  public canEditProducts(): Promise<boolean> {
    if (this._organizationStorage.partnerId) {
      return firstValueFrom(this.partner(this._organizationStorage.partnerId).pipe(pluck('data', 'menuSource'), map((e) => e === 'cms')))
    }
    return Promise.resolve(false);
  }

  //[USE] GET    /v1/admin/partner/partners/{partnerId}/metabase-report-url/{dashboardId}  Генерация ссылки на отчет.
  public metabase(): Observable<TypeResponseData<{ url: string }>> {
    return this.get<TypeResponseData<{ url: string }>>(`metabase/dashboard-url`);
  }

  //[USE] GET    /v1/admin/partner/partners/{partnerId} Получение данных партнера.
  public partner(partnerId: string): Observable<TypeResponseData<TypePartner>> {
    return this.get<TypeResponseData<TypePartner>>(`partners/${partnerId}`);
  }

  //[USE] GET    /v1/admin/partner/partners/{partnerId} Получение списка партнеров.
  public partners(): Observable<TypeResponseData<TypePartner[]> & {meta:any}> {
    return this.get<TypeResponseData<TypePartner[]> & {meta:any}>(`partners?limit=1000`);
  }
  public createPartner(partner: any): Observable<any> {
    return this.post<any, any>(`partners`, partner);
  }
  public updatePartner(id: string, partner: TypePartner): Observable<TypeResponseData<TypePartner>> {
    return this.patch<TypeResponseData<TypePartner>, TypePartner>(`partners/${id}`, partner);
  }

  public getPartnerUsers(partnerId: string): Observable<TypeResponseData<Array<IPartnerUser>>> {
    const params: RepositoryParams = {page: 1, limit: 1000};
    return this.get<TypeResponseData<Array<IPartnerUser>>>(`partners/${partnerId}/users`, params);
  }
  public addPartnersUser(partnerId: string, email: string): Observable<never> {
    return this.post<never, {email: string}>(`partners/${partnerId}/users`, {email});
  }
  public deletePartnersUser(partnerId: string, userId: string): Observable<never> {
    return this.delete<never>(`partners/${partnerId}/users/${userId}`);
  }

}
