import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import {
  API_GATEWAY_URL,
  ID,
  Entities,
  EntityCreate,
  EntityPath,
  EntityPathReq,
  TypeResponseData,
  TypeResponsePaginated,
  TypeBrand,
  TypeDiscount,
  TypeProductStock,
  TypeSeoTemplate,
  TypeWebPage,
  TypePriceMultiplier,
  TypeBanner,
  ICategory,
  IProduct,
  TypeShortProduct,
  ICategoryCreateRequest,
  TypeResponseFilters,
  TypeCategoryEditRequest, TypeCategoriesFilters,
} from '@app/shared';
import { HttpService } from '@app/core/repositories/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/cms';
  protected readonly contextOptions: Partial<ContextOptions> = { withoutCache: true };

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[USE] PUT    /v1/admin/cms/menu/{kitchenId} Копирование меню
  public copyMenu(kitchenId: string, targetKitchenId: string): Observable<never> {
    const data = {targetKitchenId};
    return this.put<never, { targetKitchenId: string }>(`menu/${kitchenId}`, data);
  }

  //[USE] POST   /v1/admin/cms/menu/export Выгрузка меню
  public updateAggregatorsMenu(kitchenId: string, channel?: string): Observable<never> {
    const data = {kitchen: kitchenId, channel: channel};
    return this.post<never, any>(`menu/export`, data);
  }

  //[USE] GET    /v1/admin/products/{productId} [DOC]
  public product(productId: ID<IProduct>): Observable<TypeResponseData<IProduct>> {
    return this.get<TypeResponseData<IProduct>>(`products/${productId}`);
  }

  //[USE] PATCH  /v1/admin/products/{productId} [DOC]
  public editProduct(product: IProduct): Observable<TypeResponseData<IProduct>> {
    return this.patch<TypeResponseData<IProduct>, IProduct>(`products/${product.id}`, product);
  }

  //[USE] GET    /v1/admin/cms/products-list Список продуктов для Админки лояльности (type = dish, active = true, deleted = false)
  public productsList(): Observable<TypeResponseData<Entities<TypeShortProduct>>> {
    return this.get<TypeResponseData<Entities<TypeShortProduct>>>('products-list'); //no params
  }

  //[USE] POST   /v1/admin/products/stocks/batch Массовое обновление стоков для продукта
  public updateProductStocks(productId: ID<IProduct>, stocks: Array<TypeProductStock>): Observable<TypeResponseData<never>> {
    const data = {productId, stocks};
    return this.post<TypeResponseData<never>, any>('products/stocks/batch', data);
  }

  //[NOT] POST   /v1/admin/products/{productId}/categories/{categoryId} Добавление продукта в категорию
  public addCategory(productId: ID<IProduct>, categoryId: string): Observable<never> {
    return this.post<never, any>(`products/${productId}/categories/${categoryId}`, {});
  }

  //[USE] DELETE /v1/admin/products/{productId}/categories/{categoryId} Удаление продукта из категории
  public removeCategory(productId: ID<IProduct>, categoryId: string): Observable<never> {
    return this.delete<never>(`products/${productId}/categories/${categoryId}`);
  }

  //[USE] GET    /v1/admin/cms/categories Возвращает детальную информацию о категориях
  public getCategories(options: TypeCategoriesFilters): Observable<TypeResponsePaginated<Array<ICategory>>> {
    const {page, limit, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams, {skipEmpty: true});
    return this.get<TypeResponsePaginated<Array<ICategory>>>('categories', params); //Verify paginated
  }

  //[USE] POST   /v1/admin/cms/categories Создание категории
  public createCategory(category: ICategoryCreateRequest): Observable<TypeResponseData<ICategory>> {
    return this.post<TypeResponseData<ICategory>, ICategoryCreateRequest>('categories', category);
  }

  //[USE] GET    /v1/admin/cms/categories/{categoryId} Получение данных категории
  public getCategory(categoryId: ID<ICategory>): Observable<TypeResponseData<ICategory>> {
    return this.get<TypeResponseData<ICategory>>(`categories/${categoryId}`);
  }

  //[USE] PATCH  /v1/admin/cms/categories/{categoryId} Обновление категории
  public editCategory(category: EntityPath<TypeCategoryEditRequest>): Observable<TypeResponseData<ICategory>> {
    return this.patch<TypeResponseData<ICategory>, EntityPath<ICategory>>(`categories/${category.id}`, category);
  }

  //[USE] DELETE /v1/admin/cms/categories/{categoryId} Удаление категории
  public deleteCategory(categoryId: ID<ICategory>): Observable<never> {
    return this.delete<never>(`categories/${categoryId}`);
  }

  //[USE] POST   /v1/admin/cms/products/categories/batch-attach Массовое добавление блюд в категорию
  public attachCategory(request: { categories: Array<{ categoryId: ID<ICategory>, products: Array<ID<IProduct>> }> }): Observable<never> {
    return this.post<never, { categories: Array<{ categoryId: ID<ICategory>, products: Array<ID<IProduct>> }> }>('products/categories/batch-attach', request);
  }

  //[USE] POST   /v1/admin/cms/products/categories/batch-detach Массовое удаление блюд из категории
  public detachCategory(request: { categories: Array<{ categoryId: ID<ICategory>, products: Array<ID<IProduct>> }> }): Observable<never> {
    return this.post<never, { categories: Array<{ categoryId: ID<ICategory>, products: Array<ID<IProduct>> }> }>('products/categories/batch-detach', request);
  }

  //[NOT] GET    /v1/admin/cms/cuisines Информация о брендах
  public cuisines(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeBrand>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeBrand>>>('cuisines', params); //Verify paginated
  }

  //[USE] GET    /v1/admin/cms/channels Информация о каналах
  public channels(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Array<string>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Array<string>>>('channels', params); //Verify paginated
  }

  //[USE] GET    /v1/admin/cms/pages Информация о страницах
  public getWebPages(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeWebPage>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeWebPage>>>('pages', params); //Verify paginated
  }

  //[USE] POST   /v1/admin/cms/pages Создание страницы
  public createWebPage(webPage: EntityCreate<TypeWebPage>): Observable<TypeResponseData<TypeWebPage>> {
    return this.post<TypeResponseData<TypeWebPage>, EntityCreate<TypeWebPage>>('pages', webPage);
  }

  //[USE] GET    /v1/admin/cms/pages/{pageId} Получение данных страницы
  public getWebPage(webPageId: ID<TypeWebPage>): Observable<TypeResponseData<TypeWebPage>> {
    return this.get<TypeResponseData<TypeWebPage>>(`pages/${webPageId}`);
  }

  //[USE] PATCH  /v1/admin/cms/pages/{pageId} Обновление страницы
  public updateWebPage(webPage: EntityPath<TypeWebPage>): Observable<TypeResponseData<TypeWebPage>> {
    const {id: webPageId} = webPage;
    return this.patch<TypeResponseData<TypeWebPage>, EntityPath<TypeWebPage>>(`pages/${webPageId}`, webPage);
  }

  //[USE] DELETE /v1/admin/cms/pages/{pageId} Удаление страницы
  public deletePage(webPageId: ID<TypeWebPage>): Observable<never> {
    return this.delete<never>(`pages/${webPageId}`);
  }

  //[NOT] GET    /v1/admin/cms/seo-templates Информация о Seo templates
  public seoTemplates(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeSeoTemplate>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeSeoTemplate>>>('seo-templates', params); //Verify paginated
  }

  //[NOT] GET    /v1/admin/cms/seo-templates/{type} Получение данных шаблона [DOC] todo: не рабочий метод
  public seoTemplate(type: TypeSeoTemplate['type']): Observable<TypeResponseData<TypeSeoTemplate>> {
    return this.get<TypeResponseData<TypeSeoTemplate>>(`seo-templates/${type}`);
  }

  //[NOT] PATCH  /v1/admin/cms/seo-templates/{type} Обновление шаблона  [DOC] todo: не рабочий метод
  public updateSeoTemplate(template: EntityPathReq<TypeSeoTemplate, 'type'>): Observable<TypeResponseData<TypeSeoTemplate>> {
    const {type} = template;
    return this.patch<TypeResponseData<TypeSeoTemplate>, EntityPathReq<TypeSeoTemplate, 'type'>>(`seo-templates/${type}`, template);
  }

  //[NOT] DELETE /v1/admin/cms/seo-templates/{type} Удаление шаблона
  public deleteTemplate(type: TypeSeoTemplate['type']): Observable<never> {
    return this.delete<never>(`seo-templates/${type}`);
  }

  //[USE] GET    /v1/admin/cms/discounts Информация о скидочных акциях
  public getDiscounts(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeDiscount>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeDiscount>>>('discounts', params); //Verify paginated
  }

  //[USE] POST   /v1/admin/cms/discounts Создание скидочной акции
  public createDiscount(discount: EntityCreate<TypeDiscount>): Observable<TypeResponseData<TypeDiscount>> {
    return this.post<TypeResponseData<TypeDiscount>, EntityCreate<TypeDiscount>>('discounts', discount);
  }

  //[USE] GET    /v1/admin/cms/discounts/{discountId} Информация о скидочной акции
  public getDiscount(discountId: ID<TypeDiscount>): Observable<TypeResponseData<TypeDiscount>> {
    return this.get<TypeResponseData<TypeDiscount>>(`discounts/${discountId}`);
  }

  //[USE] PATCH  /v1/admin/cms/discounts/{discountId} Обновление скидочной акции
  public updateDiscount(discount: EntityPath<TypeDiscount>): Observable<TypeResponseData<TypeDiscount>> {
    return this.patch<TypeResponseData<TypeDiscount>, EntityPath<TypeDiscount>>(`discounts/${discount.id}`, discount);
  }

  //[USE] GET    /v1/admin/cms/price-multipliers Информация о коэффициентах цен в разрезе кухни/канала
  public getPriceMultipliers(): Observable<TypeResponseData<Array<TypePriceMultiplier>>> {
    return this.get<TypeResponseData<Array<TypePriceMultiplier>>>(`price-multipliers`); //no params
  }

  //[USE] PUT    /v1/admin/cms/price-multipliers Обновление коэффициентов цен
  public updatePriceMultipliers(data: { priceMultipliers: Array<TypePriceMultiplier> }): Observable<TypeResponseData<Array<TypePriceMultiplier>>> {
    return this.put<TypeResponseData<Array<TypePriceMultiplier>>, { priceMultipliers: Array<TypePriceMultiplier> }>(`price-multipliers`, data);//no params
  }

  //[USE] POST   /v1/admin/cms/banner/default Upsert первого баннера
  public bannerDefault(s3Key: string, title: string = 'title', action: any = null): Observable<TypeResponseData<TypeBanner>> {
    return this.http.post<TypeResponseData<TypeBanner>, {}>(this.pathConstruct('banner/default'),
      {title, action, image: s3Key},
      {context: this.setContextOptions({...this.contextOptions, withoutCache: true})}
    );
  }

  //[USE] POST   /v1/admin/cms/images Загрузка изображения продукта [DOC]
  public addProductImage(productId: ID<IProduct>, image: File): Observable<TypeResponseData<IProduct>> {
    const formData = new FormData();
    formData.append('productId', productId);
    formData.append('image', image);
    return this.http.post<TypeResponseData<IProduct>, FormData>(this.pathConstruct('images'), formData,
      {context: this.setContextOptions({...this.contextOptions, withoutCache: true})}
    );
  }

  //[USE] DELETE /v1/admin/cms/images/{imageId} Удаление изображения продукта [DOC]
  public deleteProductImage(imageId: string): Observable<never> {
    return this.delete<never>(`images/${imageId}`);
  }

  //[NOT] GET    /v1/admin/cms/menu/products Список продуктов по кухни+категории
  //[NOT] GET    /v1/admin/cms/menu/product-modifiers Список модификаторов по продукту+кухни
  //[NOT] GET    /v1/admin/cms/modifiers-list Список продуктов (type = modifier, active = true, deleted = false)
  //[NOT] POST   /v1/admin/products/{productId}/stocks Включить сток для продукта
  //[NOT] DELETE /v1/admin/products/{productId}/stocks Выключить сток для продукта
  //[NOT] GET    /v1/admin/cms/products-stop-list Информация о продуктах в стоп листе
  //[NOT] GET    /v1/admin/cms/pages/get-by-url Получение страницы по url
  //[NOT] GET    /v1/admin/cms/banners Информация о баннерах
  //[NOT] POST   /v1/admin/cms/banners Создание баннера
  //[NOT] GET    /v1/admin/cms/banners/{bannerId} Информация о баннере
  //[NOT] PATCH  /v1/admin/cms/banners/{bannerId} Обновление баннера
}
