import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';

import { SpinnerService } from '@app/libs/spinner';
import { API_GATEWAY_URL, APP_PLATFORM } from '@app/shared';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private _totalRequests = 0;
  protected readonly apiGatewayUrl: string = inject(API_GATEWAY_URL);
  protected readonly devicePlatform: string = inject(APP_PLATFORM);

  constructor(
    private readonly _spinnerService: SpinnerService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isSpinnerDisabled = this._spinnerService.disabled
    const disabledUrls = this._spinnerService.disabledUrls;

    if (!isSpinnerDisabled && disabledUrls.findIndex((disabledUrl) => {
      if (typeof disabledUrl == 'string') {
        return disabledUrl === request.url;
      }
      return disabledUrl.test(request.url);
    }) == -1) {
      this._totalRequests++;
      this._spinnerService.start();
    }

    let updateRequest: HttpRequest<any>;
    if (request.url.startsWith(this.apiGatewayUrl) && this.devicePlatform) {
      updateRequest = request.clone({headers: request.headers.set('X-App-Platform', this.devicePlatform)});
    } else {
      updateRequest = request;
    }

    return next.handle(updateRequest).pipe(
      finalize(() => {
        if (!isSpinnerDisabled && this._totalRequests > 0) {
          this._totalRequests--;
        }

        if (!this._totalRequests) {
          this._spinnerService.stop();
        }
      }),
    );
  }
}
