
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  isExpanded$ = new BehaviorSubject<boolean>(false);
  isHidden$ = new BehaviorSubject<boolean>(false);
}
