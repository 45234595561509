import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import {
  API_GATEWAY_URL, Entities, EntityPath, TypeIntegrationConfig,
  TypeIntegrationsSalesFilters,
  TypeResponseData
} from '@app/shared';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/integration';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[USE] GET    /v1/admin/integration/configs/{type} Информация о конфигурации [DOC] todo: добавить пагинацию в метод апи
  public getSalesConfigs(options: TypeIntegrationsSalesFilters): Observable<TypeResponseData<Entities<TypeIntegrationConfig>>> {
    const {page, limit, type, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams, {skipEmpty: false});
    return this.get<TypeResponseData<Entities<TypeIntegrationConfig>>>(`configs/${type}`, params);
  }

  //[USE] GET    /v1/admin/integration/config/{configId} Получение конфига по ID
  public getKitchenConfigs(kitchenId: string): Observable<TypeResponseData<TypeIntegrationConfig>> {
    return this.get<TypeResponseData<TypeIntegrationConfig>>(`config/${kitchenId}`);
  }

  //[USE] PATCH  /v1/admin/integration/config/{configId} Редактирование конфигурации
  public updateConfigs(config: EntityPath<TypeIntegrationConfig>): Observable<TypeResponseData<TypeIntegrationConfig>> {
    return this.patch<TypeResponseData<TypeIntegrationConfig>, EntityPath<TypeIntegrationConfig>>(`config/${config.id}`, config);
  }

  public updateKitchenConfigs(kitchenId: string, config: Partial<EntityPath<TypeIntegrationConfig>>): Observable<TypeResponseData<TypeIntegrationConfig>> {
    return this.patch<TypeResponseData<TypeIntegrationConfig>, Partial<EntityPath<TypeIntegrationConfig>>>(`config/${kitchenId}`, config);
  }

  //[USE] POST   /v1/admin/integration/accepting-order/{configId} Включение/выключение приема заказов в интеграциях
  public acceptingOrder(configId: string, data: { status: true, reason?: string }): Observable<never> {
    return this.post<never, { status: true, reason?: string }>(`accepting-order/${configId}`, data);
  }
}
