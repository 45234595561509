/* eslint-disable no-undef */

/* eslint-disable no-console */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableProdMode, StaticProvider } from '@angular/core';

import { AppModule } from './app/app.module';
import { initSentryBrowser } from './sentry';
import { Configuration, envExtraProviders, ENVIRONMENT, globalEnv } from '@app/shared';

const environment: Configuration = globalEnv(true);
const extraProviders: Array<StaticProvider> = [{provide: ENVIRONMENT, useValue: environment}, ...envExtraProviders];

initSentryBrowser(environment);

if (environment?.ng?.buildModeProduction) {
  enableProdMode();
}

platformBrowserDynamic(extraProviders)
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
  });
