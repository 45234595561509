import { DeliveryMethods, DeliveryProviders } from '@app/shared';

export function normalizeDeliveryMethod(method: string): string {
  switch (method) {
    case DeliveryMethods.TAXI:
      return 'SHARED.DELIVERY_STATUS.TAXI';
    case DeliveryMethods.COURIER:
      return 'SHARED.DELIVERY_STATUS.COURIER';
    case DeliveryMethods.PICKUP:
      return 'SHARED.DELIVERY_STATUS.PICKUP';
    case DeliveryMethods.DINE_IN:
      return 'SHARED.DELIVERY_STATUS.DINE_IN';
    case DeliveryMethods.AGGREGATOR_COURIER:
      return 'SHARED.DELIVERY_STATUS.AGGREGATOR_COURIER';
    default:
      return 'SHARED.DELIVERY_STATUS.DEFAULT';
  }
}

export function normalizeDeliveryProvider(method: string): string {
  switch (method) {
    case DeliveryProviders.WOLTDRIVE:
      return 'SHARED.DELIVERY_PROVIDER.WOLTDRIVE';
    case DeliveryProviders.INTERNAL:
    default:
      return 'SHARED.DELIVERY_PROVIDER.INTERNAL';
  }
}
