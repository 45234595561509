import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LocalStorageService, PartnerRepository } from '@app/shared';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './keycloak-initializer';
import { AuthService } from './services/auth.service';
import { KEYCLOAK_CFG } from '@app/shared';

@NgModule({
  declarations: [],
  imports: [KeycloakAngularModule],
  providers: [
    {
        provide: APP_INITIALIZER,
        useFactory: initializer,
        multi: true,
        deps: [KeycloakService, LocalStorageService, KEYCLOAK_CFG]
    },
    AuthService
  ]
})
export class AuthConfigModule { }
