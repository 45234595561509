import { Component, OnInit, OnDestroy } from '@angular/core';
import { Toaster, ToasterService } from './toaster.service';
import { Subscription } from 'rxjs';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('toasterAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ transform: 'translateX(100%)', opacity: 0 }),
          stagger('200ms', [
            animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
          ])
        ], { optional: true }),
        query(':leave', [
          stagger('200ms', [
            animate('300ms ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))
          ])
        ], { optional: true }),
        query('@*', [
          animate('300ms', style({ transform: 'translateY(-100%)' }))
        ], { optional: true })
      ])
    ])
  ]
})
export class ToasterComponent implements OnInit, OnDestroy {
  toasters$ = this.toasterService.toasters$;
  private subscription!: Subscription;

  constructor(private toasterService: ToasterService, private router: Router) {}

  ngOnInit() {
    this.subscription = this.toasterService.toasters$.subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(id: number) {
    this.toasterService.remove(id);
  }

  navigate(toaster: Toaster) {
    if (toaster.redirectTo) {
      this.router.navigateByUrl(toaster.redirectTo);
    }
  }
}
