import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  API_GATEWAY_URL,
  IProduct,
  TypeResponseData,
  TypeResponsePaginated,
  ID,
  EntityCreate,
  EntityPath,
  EntityPut,
  Entities,
  TypeBrand,
  TypeProduct,
  TypeProductCategory,
  TypeProductModifier,
  TypeProductModifierMain,
  TypeModifierGroup,
  TypeTechGroup,
  TypeProductEnergy,
  TypeProductSeo,
  TypeProductPrice,
  TypeProductStock,
  TypeProductModifiers,
  TypeProductsFilters,
  TypeResponseFilters, TypeProductMain, EntityNamed
} from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class CmsV2Repository extends Repository {
  protected readonly version = RepositoryVersion.v2;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/cms';
  protected readonly contextOptions: Partial<ContextOptions> = { withoutCache: true };

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[NOT] GET    /v2/cms/pos/menu get menu for POS by kitchen

  //[USE] GET    /v2/admin/cms/products-base   Информация о продуктах [DOC]
  public getProducts(options: TypeProductsFilters): Observable<TypeResponsePaginated<Entities<IProduct>>> {
    console.log('getProducts');
    
    const {page, limit, categoryIds, ids, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    if (ids?.length) {
      ids.forEach((v, i) => {
        if (v) {
          a[`filter[ids][${i}]`] = String(v);
        }
      })
    }
    if (categoryIds?.length) {
      categoryIds.forEach((v, i) => {
        if (v) {
          a[`filter[categoryIds][${i}]`] = String(v);
        }
      })
    }
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams, {skipEmpty: true}); //filter[ids] filter[categoryIds][]
    return this.get<TypeResponsePaginated<Entities<IProduct>>>('products-base', params); //Verify paginated
  }


  //[USE] POST   /v2/admin/cms/products   Создание продукта
  public createProduct(product: EntityCreate<TypeProduct>): Observable<TypeResponseData<TypeProduct>> {
    return this.post<TypeResponseData<TypeProduct>, EntityCreate<TypeProduct>>('products', product);
  }

  //[USE] POST   /v2/admin/cms/modifiers   Создание модификатора
  public createModifier(modifier: EntityCreate<TypeProductModifier>): Observable<TypeResponseData<TypeProductModifier>> {
    return this.post<TypeResponseData<TypeProductModifier>, EntityCreate<TypeProductModifier>>('modifiers', modifier);
  }

  //[USE] POST   /v2/admin/cms/products/{productId}/create-child Создание дочернего продукта
  public createProductChild(productId: ID<IProduct>): Observable<TypeResponseData<IProduct>> {
    return this.post<TypeResponseData<IProduct>, any>(`products/${productId}/create-child`, null);
  }

  //[USE] GET    /v2/admin/cms/products/{productId}/main Получение main данных продукта
  public getProduct(productId: ID<TypeProductMain>): Observable<TypeResponseData<TypeProductMain>> {
    return this.get<TypeResponseData<TypeProductMain>>(`products/${productId}/main`);
  }

  //[USE] PATCH  /v2/admin/cms/products/{productId}/main Обновление main данных продукта
  //Список полей которые не нужно отправлять при menu data source != cms: 'internalName', 'vatRate', 'techGroupId', 'modifierGroups'. BE будет возвращать валидационную ошибку если будет передан какой то из этих полей.
  public editProduct(product: EntityPath<TypeProductMain>, productId: string): Observable<TypeResponseData<TypeProductMain>> {
    return this.patch<TypeResponseData<TypeProductMain>, EntityPath<TypeProductMain>>(`products/${productId}/main`, product).pipe(
      map(res => res ?? {})
    );
  }

  //[USE] DELETE /v2/admin/cms/products/{productId} Удаление продукта [DOC]
  public removeProduct(productId: string): Observable<never> {
    return this.delete<never>(`products/${productId}`);
  }

  //[USE] GET    /v2/admin/cms/modifiers/{modifierId}/main Получение main данных модификатора
  public getModifier(modifierId: ID<TypeProductModifierMain>): Observable<TypeResponseData<TypeProductModifierMain>> {
    return this.get<TypeResponseData<TypeProductModifierMain>>(`modifiers/${modifierId}/main`);
  }
  //[USE] PATCH  /v2/admin/cms/modifiers/{modifierId}/main Обновление main данных модификатора
  //Список полей которые не нужно отправлять при menu data source != cms: 'internalName', 'vatRate', 'modifierGroupId'. BE будет возвращать валидационную ошибку если будет передан какой то из этих полей.
  public editModifier(modifier:  EntityPath<TypeProductModifierMain>, modifierId: string): Observable<TypeResponseData<TypeProductModifierMain>> {
    return this.patch<TypeResponseData<TypeProductModifierMain>,  EntityPath<TypeProductModifierMain>>(`modifiers/${modifierId}/main`, modifier).pipe(
      map(res => res ?? {})
    );
  }

  //[USE] GET    /v2/admin/cms/products/{productId}/energy Получение energy данных продукта
  public getProductEnergy(productId: string): Observable<TypeResponseData<TypeProductEnergy>> {
    return this.get<TypeResponseData<TypeProductEnergy>>(`products/${productId}/energy`);
  }
  //[USE] GET    /v2/admin/cms/products/{productId}/seo Получение seo данных продукта
  public getProductSeo(productId: string): Observable<TypeResponseData<TypeProductSeo>> {
    return this.get<TypeResponseData<TypeProductSeo>>(`products/${productId}/seo`);
  }
  //[USE] PATCH  /v2/admin/cms/products/{productId}/seo Обновление seo данных продукта
  public editProductSeo(productId: string, seo: Partial<TypeProductSeo>): Observable<TypeResponseData<TypeProductSeo>> {
    return this.patch<TypeResponseData<TypeProductSeo>, Partial<TypeProductSeo>>(`products/${productId}/seo`, seo);
  }

  //[USE] GET    /v2/admin/cms/products/{productId}/stocks Получение стоков продукта
  public getProductStocks(productId: string): Observable<TypeResponseData<Array<TypeProductStock>>> {
    return this.get<TypeResponseData<Array<TypeProductStock>>>(`products/${productId}/stocks`); //no pagination
  }
  //[USE] GET    /v2/admin/cms/products/{productId}/modifier-groups Получение модификаторов продукта
  public getProductModifiers(productId: string): Observable<TypeResponseData<TypeProductModifiers>> {
    return this.get<TypeResponseData<TypeProductModifiers>>(`products/${productId}/modifier-groups`); //no pagination
  }

  //[USE] PUT    /v2/admin/cms/products/{productId}/modifier-groups Обновление групп модификаторов, привязанных к продукту
  public updateProductModifiers(productId: string, modifierGroups: Entities<TypeModifierGroup>): Observable<TypeResponseData<TypeProductModifiers>> {
    return this.put<TypeResponseData<TypeProductModifiers>, { modifierGroups: Entities<TypeModifierGroup> }>(`products/${productId}/modifier-groups`, {modifierGroups});
  }

  //[NOT] GET    /v2/admin/cms/products/{productId}/categories Получение категорий продукта
  public getProductCategories(productId: string): Observable<TypeResponseData<Array<TypeProductCategory>>> {
    return this.get<TypeResponseData<Array<TypeProductCategory>>>(`products/${productId}/categories`);//no pagination
  }
  //[USE] GET    /v2/admin/cms/products/{productId}/prices Получение матрицы цен продукта
  public getProductPrices(productId: string): Observable<TypeResponseData<Array<TypeProductPrice>>> {
    return this.get<TypeResponseData<Array<TypeProductPrice>>>(`products/${productId}/prices`);//no pagination
  }

  //[USE] GET    /v2/admin/cms/brands Информация о брендах
  public getBrands(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeBrand>>> {
    const {page, limit } = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeBrand>>>('brands', params); //Verify paginated
  }
  //[USE] POST   /v2/admin/cms/brands Создание бренда
  public createBrand(brand: EntityCreate<TypeBrand>): Observable<TypeResponseData<TypeBrand>> {
    return this.post<TypeResponseData<TypeBrand>, EntityCreate<TypeBrand>>('brands', brand);
  }
  //[USE] GET    /v2/admin/cms/brands/{brandId} Информация о бренде
  public getBrand(brandId: ID<TypeBrand>): Observable<TypeResponseData<TypeBrand>> {
    return this.get<TypeResponseData<TypeBrand>>(`brands/${brandId}`);
  }
  //[USE] PUT    /v2/admin/cms/brands/{brandId} Обновление бренда
  public updateBrand(brand: EntityPut<TypeBrand>): Observable<TypeResponseData<TypeBrand>> {
    return this.put<TypeResponseData<TypeBrand>, EntityPut<TypeBrand>>(`brands/${brand.id}`, brand);
  }
  //[USE] DELETE /v2/admin/cms/brands/{brandId} Удаление бренда
  public deleteBrand(brandId: ID<TypeBrand>): Observable<never> {
    return this.delete<never>(`brands/${brandId}`);
  }

  //[USE] GET    /v2/admin/cms/modifier-groups Получение данных о группах модификаторов
  public getModifierGroups(): Observable<TypeResponseData<Entities<TypeModifierGroup>>> {
    return this.get<TypeResponseData<Entities<TypeModifierGroup>>>('modifier-groups'); //no params
  }
  //[USE] POST   /v2/admin/cms/modifier-groups Создание группы модификаторов
  public createModifierGroup(modifierGroup: EntityCreate<TypeModifierGroup>): Observable<TypeResponseData<TypeModifierGroup>> {
    return this.post<TypeResponseData<TypeModifierGroup>, EntityCreate<TypeModifierGroup>>('modifier-groups', modifierGroup);
  }
  //[USE] GET    /v2/admin/cms/modifier-groups/{modifierGroupId} Получение данных о группе модификаторов
  public getModifierGroup(modifierGroupId: ID<TypeModifierGroup>): Observable<TypeResponseData<TypeModifierGroup>> {
    return this.get<TypeResponseData<TypeModifierGroup>>(`modifier-groups/${modifierGroupId}`);
  }
  //[USE] PUT    /v2/admin/cms/modifier-groups/{modifierGroupId} Обновление группы модификаторов
  public updateModifierGroup(modifierGroup: EntityPut<TypeModifierGroup>): Observable<TypeResponseData<TypeModifierGroup>> {
    return this.put<TypeResponseData<TypeModifierGroup>, EntityPut<TypeModifierGroup>>(`modifier-groups/${modifierGroup.id}`, modifierGroup);
  }
  //[USE] DELETE /v2/admin/cms/modifier-groups/{modifierGroupId} Удаление группы модификаторов
  public deleteModifierGroup(modifierGroupId: ID<TypeModifierGroup>): Observable<never> {
    return this.delete<never>(`modifier-groups/${modifierGroupId}`);
  }

  //[USE] GET    /v2/admin/cms/modifier-groups/{modifierGroupId}/modifiers Получение данных о модификаторах в группе [DOC]
  public getModifiersByGroup(modifierGroupId: ID<TypeModifierGroup>): Observable<TypeResponseData<Entities<EntityNamed>>> {
    return this.get<TypeResponseData<Entities<EntityNamed>>>(`modifier-groups/${modifierGroupId}/modifiers`);
  }

  //[USE] GET    /v2/admin/cms/tech-groups Информация о технических группах
  public getTechGroups(): Observable<TypeResponseData<Entities<TypeTechGroup>>> {
    return this.get<TypeResponseData<Entities<TypeTechGroup>>>('tech-groups'); //no params
  }
  //[USE] POST   /v2/admin/cms/tech-groups Создание технической группы
  public createTechGroup(techGroup: EntityCreate<TypeTechGroup>): Observable<TypeResponseData<TypeTechGroup>> {
    return this.post<TypeResponseData<TypeTechGroup>, EntityCreate<TypeTechGroup>>('tech-groups', techGroup);
  }
  //[USE] GET    /v2/admin/cms/tech-groups/{techGroupId} Получение данных о технической группе
  public getTechGroup(techGroupId: ID<TypeTechGroup>): Observable<TypeResponseData<TypeTechGroup>> {
    return this.get<TypeResponseData<TypeTechGroup>>(`tech-groups/${techGroupId}`);
  }
  //[USE] PUT    /v2/admin/cms/tech-groups/{techGroupId} Обновление технической группы
  public updateTechGroup(techGroup: EntityPut<TypeTechGroup>): Observable<TypeResponseData<TypeTechGroup>> {
    return this.put<TypeResponseData<TypeTechGroup>, EntityPut<TypeTechGroup>>(`tech-groups/${techGroup.id}`, techGroup);
  }
  //[USE] DELETE /v2/admin/cms/tech-groups/{techGroupId} Удаление технической группы
  public deleteTechGroup(techGroupId: ID<TypeTechGroup>): Observable<never> {
    return this.delete<never>(`tech-groups/${techGroupId}`);
  }
}
