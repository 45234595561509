import { DeliveryProviders, Nullable, TypeLocation } from '@app/shared';

// ---- new interfaces start
export enum ChannelType {
  DELIVERY = 'delivery-channel',
  ORDER = 'orders-channel'
}

export enum EventType {
  //
  ORDER_CREATED = 'order-created-event',
  // order status
  ORDER__CONFIRMATION_AWAITING = 'order--confirmation-awaiting',
  ORDER__IN_PROGRESS = 'order--in-progress',
  ORDER__READY_FOR_DELIVERY = 'order--ready-for-delivery',
  ORDER__IN_DELIVERY = 'order--in-delivery',
  ORDER__FITTING = 'order--fitting',
  ORDER__DELIVERED = 'order--delivered',
  ORDER__CANCELLED = 'order--cancelled',
  // courier actions
  ORDER__COURIER_ASSIGNED = 'order--courier-assigned',
  ORDER__COURIER_CANCELLED = 'order--courier-cancelled',
  //delivery status
  ORDER__DELIVERY_IN_RETURN = 'order--delivery-in-return',
  ORDER__DELIVERY_DELIVERED = 'order--delivery-delivered',
  ORDER__DELIVERY_RETURNED = 'order--delivery-returned',
  // DELIVERY
  DELIVERY_COURIER_STATUS = 'courier-status-updated-event',
  DELIVERY_COURIER_LOCATION = 'courier-location-updated-event',
  DELIVERY_VENUE_ACTIVITY = 'venue-activity-updated-event'
}

export interface IOrderPayload {
  id: string,
  number: string,
  venueId: string,
  partner: string,
  status: string,
  deliveryStatus: string,
  items: Array<IOrderItemPayload>;
}

export interface IOrderItemPayload {
  id: string,
  offerId: string,
  name: string,
  status: string,
}

export interface IOrderReference {
  orderId: string
}

export interface IOrderCourierEvent extends IOrderReference {
  courierId: string
}

export interface IOrderDeliveryStatusEvent extends IOrderReference {
  deliveryStatus: string
}

export interface IOrderStatusEvent extends IOrderReference {
  order: IOrderPayload,
  status: string
}
export interface IVenueStatusEvent {
  isEnabled: boolean,
  venue: string
}

// delivery events
export interface ICourierStatusEvent {
  courierId: string;
  status: string;
}

export interface ICourierLocationEvent {
  courierId: string;
  currentLocation: TypeLocation;
}


// ---- new interfaces finish

export interface IOrder {
  aggregatorCode: Nullable<string>;
  appliedCashback: Nullable<string>;
  channel: string;
  cookingTime: number;
  country: string;
  courierId: Nullable<string>;
  courierInfo: Nullable<{ name: string, phone: string }>;
  createdAt: Date;
  createdBy: string;
  currency: string;
  customerCashAmount: Nullable<number>;
  customerData?: ICustomer;
  customerId: string;
  cutlery: number;
  deliveryAddressCoordinates: Nullable<TypeLocation>;
  deliveryAddressString: Nullable<string>;
  deliveryFee: number;
  deliveryMethod: string;
  deliveryTime: number;
  discount: Nullable<number>;
  externalId: Nullable<string>;
  finishedAt: Nullable<Date>;
  id: string;
  kitchenId: string;
  number: string;
  paymentMethod: string;
  products: Array<IProduct>;
  promoCode: Nullable<string>;
  promoConstraints: Nullable<Array<IPromoConstraint>>;
  scheduledCookingTime: Date;
  scheduledDeliveryTime: Date;
  status: string;
  surcharge: Nullable<number>;
  taxReceiptLink: Nullable<number>;
  total: number;
  totalWithDiscount: number;
  updatedAt: Date;
  updatedBy: Nullable<string>;
  useCashback: boolean;
  deliveryProvider: DeliveryProviders
}



interface ICustomer {
  averageOrderPrice: Nullable<number>;
  birthday: Nullable<Date>;
  comment: Nullable<string>;
  createdAt: Date;
  deviceId: Nullable<string>;
  email: Nullable<string>;
  id: string;
  isAnonymous: boolean;
  isNew: boolean;
  lang: Nullable<string>;
  name: string;
  ordersTotalSum: number;
  phone: string
  updatedAt: Date;
}



interface IProduct {
  discount: Nullable<number>;
  id: string;
  isGift: boolean;
  name: string;
  price: number;
  priceWithDiscount: number;
  productId: string;
  quantity: number;
}


interface IPromoConstraint {
  cause: string;
  metadata: IPromoConstraintMetadata;
  promoCode: string;
}


interface IPromoConstraintMetadata {
  minOrderTotal?: number;
  maxCustomerUsage?: number;
}
