import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Nullable } from '@app/shared';

/** @deprecated todo: refactor */
@Injectable()
export class SidenavRouteService implements OnDestroy {
  public readonly saveFn = new BehaviorSubject<Nullable<() => Promise<unknown>>>(null);
  public readonly deleteFn = new BehaviorSubject<Nullable<() => Promise<unknown>>>(null);
  public readonly leaveEventFn = new BehaviorSubject<Nullable<() => Promise<unknown>>>(null);

  public readonly isCreate = new BehaviorSubject(false);
  public readonly isCancel = new BehaviorSubject(false);
  public readonly hasDelete = new BehaviorSubject(false);
  public readonly saveDisabled = new BehaviorSubject(false);
  public readonly isHidden = new BehaviorSubject(false);

  public readonly updateData = new EventEmitter<void>();
  public readonly savedEvent = new EventEmitter<void>();


  public ngOnDestroy(): void {
    this.updateData.complete();
    this.saveFn.complete();
    this.deleteFn.complete();
    this.hasDelete.complete();
    this.isCreate.complete();
    this.isCancel.complete();
    this.saveDisabled.complete();
    this.savedEvent.complete();
  }
}
