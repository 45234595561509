import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import { Observable } from 'rxjs';
import { pluck, shareReplay } from 'rxjs/operators';
import { API_GATEWAY_URL, PaymentMethods, TypeResponseData, TypeVatRate } from '@app/shared';


@Injectable({
  providedIn: 'root'
})
export class DictionariesRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'dictionaries';
  protected readonly contextOptions: Partial<ContextOptions> = { withoutCache: true };

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[USE] GET    /v1/dictionaries/payment-methods [DOC] todo: нет документации
  public paymentMethods(): Observable<TypeResponseData<Array<PaymentMethods>>> {
    return this.get<TypeResponseData<Array<PaymentMethods>>>('payment-methods');//no params
  }

  //[USE] GET    /v1/dictionaries/courier-statuses [DOC] todo: нет документации
  public courierStatuses(): Observable<TypeResponseData<Array<string>>> {
    return this.get<TypeResponseData<Array<string>>>('courier-statuses');//no params
  }

  //[USE] GET    /v1/dictionaries/integrations [DOC] todo: нет документации
  public integrations(): Observable<TypeResponseData<Array<string>>> {
    return this.get<TypeResponseData<Array<string>>>('integrations');//no params
  }

  //[USE] GET    /v1/dictionaries/vat-rates Информация о доступных налоговых ставках
  public vatRatesList(): Observable<TypeResponseData<Array<TypeVatRate>>> {
    return this.get<TypeResponseData<Array<TypeVatRate>>>('vat-rates');//no params
  }

  //[USE] GET    /v1/dictionaries/accepting-order-reasons Справочник reason для Включение/выключение приема заказов в интеграциях
  public getAcceptingOrderReasons(): Observable<TypeResponseData<Array<string>>> {
    return this.get<TypeResponseData<Array<string>>>('accepting-order-reasons');//no params
  }

  //[USE] GET    /v1/dictionaries/delivery-methods [DOC] todo: нет документации
  public getDeliveryMethods(): Observable<TypeResponseData<Array<string>>> {
    return this.get<TypeResponseData<Array<string>>>('delivery-methods'); //no params
  }

  //[USE] GET /v1/admin/dictionaries/delivery-providers Справочник внешних сервисов курьерской доставки
  public getDeliveryProviders(): Observable<TypeResponseData<Array<string>>> {
    return this.http.get<TypeResponseData<Array<string>>>([this.apiGatewayUrl, this.version, 'admin/dictionaries', 'delivery-providers'].filter(Boolean).join('/')); //no params
  }

}
