import { inject, InjectionToken } from '@angular/core';
import { DOCUMENT, PlatformLocation } from '@angular/common';

export const WINDOW = new InjectionToken<Window | undefined>(
  'WINDOW_TOKEN',
  // eslint-disable-next-line no-undef
  typeof window !== 'undefined' && window.document ? {providedIn: 'root', factory: () => window} : {providedIn: 'root', factory: () => undefined}
);

export const WINDOW_NAVIGATOR = new InjectionToken<Navigator | undefined>(
  'WINDOW_NAVIGATOR_TOKEN',
  {providedIn: 'root', factory: () => inject(WINDOW)?.navigator}
);

export const WINDOW_NAVIGATOR_LANGUAGE = new InjectionToken<NavigatorLanguage['language'] | undefined>(
  'WINDOW_NAVIGATOR_LANGUAGE_TOKEN',
  {providedIn: 'root', factory: () => inject(WINDOW)?.navigator?.language}
);

export const WINDOW_NAVIGATOR_LANGUAGES = new InjectionToken<NavigatorLanguage['languages']>(
  'WINDOW_NAVIGATOR_LANGUAGES_TOKEN',
  {providedIn: 'root', factory: () => inject(WINDOW)?.navigator?.languages || []}
);

export const WINDOW_LOCAL_STORAGE = new InjectionToken<Storage | undefined>(
  'WINDOW_LOCAL_STORAGE_TOKEN',
  {providedIn: 'root', factory: () => inject(WINDOW)?.localStorage}
);

export const WINDOW_SESSION_STORAGE = new InjectionToken<Storage | undefined>(
  'WINDOW_SESSION_STORAGE_TOKEN',
  {providedIn: 'root', factory: () => inject(WINDOW)?.sessionStorage}
);

export const APP_HOST_URL = new InjectionToken<string>('APP_HOST_URL_TOKEN', {
  providedIn: 'root',
  factory: () => new URL(inject(PlatformLocation)?.href as string)?.origin || inject(DOCUMENT).location?.origin || ''
});

export const APP_HOST = new InjectionToken<string>('APP_HOST_TOKEN', {
  providedIn: 'root',
  factory: () => new URL(inject(PlatformLocation)?.href as string)?.host || inject(DOCUMENT).location?.host || ''
});

export const APP_HOST_NAME = new InjectionToken<string>('APP_HOST_NAME_TOKEN', {
  providedIn: 'root',
  factory: () => inject(PlatformLocation)?.hostname || inject(DOCUMENT).location?.hostname || ''
});

export const APP_HOST_HREF = new InjectionToken<string>('APP_HOST_HREF_TOKEN', {
  providedIn: 'root',
  factory: () => inject(PlatformLocation)?.href || inject(DOCUMENT).location?.href || ''
});

export const APP_HOST_PATH = new InjectionToken<string>('APP_HOST_PATH_TOKEN', {
  providedIn: 'root',
  factory: () => new URL(inject(PlatformLocation)?.href as string)?.pathname || inject(DOCUMENT).location?.pathname || ''
});

export const APP_ASSETS_URL = new InjectionToken<string>('APP_ASSETS_URL_TOKEN', {
  providedIn: 'root',
  factory: () => `${inject(APP_HOST_URL)}/assets`
});
