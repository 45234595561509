import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-alert',
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.scss']
})
export class SnackbarAlertComponent {
  constructor( 
    public snackBarRef: MatSnackBarRef<SnackbarAlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
