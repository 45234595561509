import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { ToasterComponent } from './toaster.component';
import { Howl } from 'howler';

export interface Toaster {
  id: number;
  message: string;
  redirectTo?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private toastersSubject = new BehaviorSubject<Toaster[]>([]);
  public toasters$ = this.toastersSubject.asObservable();
  private nextId = 0;
  private _alertSound = new Howl({src: ['/assets/sound/alert.mp3'], autoplay: true});
  public alertMuted = true;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this._alertSound.mute(this.alertMuted);
    this._alertSound.on('playerror', () => {
      this._alertSound.once('unlock', () => {
        this._alertSound.play();
      });
    });
  }

  public alertMuteToggle(): boolean {
    this._alertSound.mute(!this.alertMuted);
    this.alertMuted = !this.alertMuted;
    return this.alertMuted;
  }

  show(message: string, redirectTo?: string, playSound?: boolean) {
    timer(10).subscribe(() => {
      document.body.focus();
      this._alertSound.play();
    });
    const toasters = this.toastersSubject.getValue();
    this.toastersSubject.next([...toasters, { id: this.nextId++, message, redirectTo }]);
    if (!document.querySelector('app-toaster')) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToasterComponent);
      const componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }
  }

  remove(id: number) {
    const toasters = this.toastersSubject.getValue().filter(toaster => toaster.id !== id);
    this.toastersSubject.next(toasters);
  }
}
