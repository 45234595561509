import { defaultLang, availableLangs } from '../../conts';
import { TRANSLOCO_CONFIG, translocoConfig } from '@ngneat/transloco';
import { inject } from '@angular/core';
import { ENVIRONMENT } from '@app/shared';

export const TRANSLOCO_HTTP_LOADER_CONFIG_PROVIDER = {
  provide: TRANSLOCO_CONFIG,
  useFactory: () => {
    const prodMode: boolean = inject(ENVIRONMENT)?.env?.isProduction ?? false;
    return translocoConfig({
      availableLangs,
      defaultLang,
      fallbackLang: defaultLang,
      missingHandler: {
        useFallbackTranslation: true
      },
      // Remove this option if your application doesn't support changing language in runtime.
      reRenderOnLangChange: true,
      prodMode
    })
  },
};
