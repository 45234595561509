import { ValidatorFn } from '@angular/forms';

export function TimeValidator(): ValidatorFn {
  return (control) => {
    const value: string = control.value;
    const allowed = /^(?:[01]\d|2[0123]):(?:[012345]\d)$/.test(value);

    const validatorInstance = {
      invalidTime: { value },
    };

    return !allowed ? validatorInstance : null;
  };
}
