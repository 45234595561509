/** ключи не менять - имеют значения в переводах */
import { ActivatedRouteSnapshot } from '@angular/router';

export enum AuthRoles {
  SUPER_ADMIN = 'super_admin',  // роль у котрой должен быть полный доступ ко всему
  PARTNER = 'partner',  // роль у котрой должен быть полный доступ ко всему
  CRM_ADMIN = 'crm_admin',
  CRM_MANAGER = 'crm_manager',
  LOYALTY_ADMIN = 'loyalty_admin',
  LOYALTY_MANAGER = 'loyalty_manager',
  DELIVERY_ADMIN = 'delivery_admin',
  DELIVERY_MANAGER = 'delivery_manager',
  OMS_ADMIN = 'oms_admin',
  OMS_MANAGER = 'oms_manager',
  CMS_ADMIN = 'cms_admin',
  KDS_ADMIN = 'kds_admin',
}

/** ключи не менять - имеют значения в переводах */
enum AuthPermissions {
  CMS_PRICING_UPDATE = 'cms.pricing.update',
  CMS_BRANDS_EDIT = 'cms.brand.admin',
  KITCHEN_CREATE = 'delivery.kitchen.create',
  INTEGRATION_CONFIG_UPDATE = 'integration.config.update'
}

export type TypeAuthRole = AuthRoles | AuthPermissions;
export type TypeAuthRoles = Array<TypeAuthRole>;
export type TypePermissions = Partial<{
  canRead: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canChangeActive: boolean;
  canEditSettings: boolean;
  // [prop: string]: boolean;
}>;

/** @deprecated */
const ALlOWED: TypeAuthRoles = [
  AuthRoles.PARTNER,
  AuthRoles.SUPER_ADMIN,
  AuthRoles.CRM_ADMIN,
  AuthRoles.CRM_MANAGER,
  AuthRoles.LOYALTY_ADMIN,
  AuthRoles.LOYALTY_MANAGER,
  AuthRoles.DELIVERY_ADMIN,
  AuthRoles.DELIVERY_MANAGER,
  AuthRoles.OMS_ADMIN,
  AuthRoles.OMS_MANAGER,
  AuthRoles.CMS_ADMIN,
];

export const SUPER_ADMIN_AUTHORISATION_ROLE: string = 'super_admin'; // роль супер-админа

/** CMS_MODULE */

// /product/list  CmsModule->ContainerComponent->ProductsComponent
export const CMS_MODULE_PERMISSION: TypeAuthRoles = [AuthRoles.CMS_ADMIN, AuthRoles.SUPER_ADMIN, AuthRoles.PARTNER];
export const CMS_MODULE_ROOT_CATEGORY: TypeAuthRoles = [AuthRoles.SUPER_ADMIN];
export const SUPER_ADMIN_PERMISSION: TypeAuthRoles = [AuthRoles.SUPER_ADMIN];
export const CMS_MODULE_PARTNER_CATEGORY: TypeAuthRoles = [AuthRoles.PARTNER];

// no route
export const CMS_PERMISSION_CATEGORIES: TypeAuthRoles = CMS_MODULE_PERMISSION;
// no route
export const CMS_PERMISSION_CATEGORY_CREATE: TypeAuthRoles = ALlOWED;
// no route
export const CMS_PERMISSION_CATEGORY_DELETE: TypeAuthRoles = CMS_PERMISSION_CATEGORY_CREATE;
// /product/list/category/edit/{categoryId}  CmsModule->ContainerComponent->ProductsComponent->CategoryComponent
export const CMS_PERMISSION_CATEGORY_EDIT: TypeAuthRoles = ALlOWED;

// no route
export const CMS_PERMISSION_BRANDS: TypeAuthRoles = CMS_MODULE_PERMISSION;
// no route
export const CMS_PERMISSION_BRAND_CREATE: TypeAuthRoles = [AuthPermissions.CMS_BRANDS_EDIT];
// no route
export const CMS_PERMISSION_BRAND_DELETE: TypeAuthRoles = CMS_PERMISSION_BRAND_CREATE;
// /product/list/brand/edit/{brandId} CmsModule->ContainerComponent->ProductsComponent->BrandComponent
export const CMS_PERMISSION_BRAND_EDIT: TypeAuthRoles = [AuthPermissions.CMS_BRANDS_EDIT];

// /product/list/partner-category/:partnerCategoryId CmsModule->ContainerComponent->ProductsComponent->PartnerCategorySidebarComponent
export const CMS_PERMISSION_PARTNER_CATEGORY_MANAGE: TypeAuthRoles = [AuthRoles.PARTNER];

// /product/list  CmsModule->ContainerComponent->ProductsComponent
export const CMS_PERMISSION_PRODUCTS: TypeAuthRoles = ALlOWED;
// /product/list/create CmsModule->ContainerComponent->ProductsComponent->ProductDetailsModule->
export const CMS_PERMISSION_PRODUCT_CREATE: TypeAuthRoles = CMS_PERMISSION_PRODUCTS;
// no route
export const CMS_PERMISSION_PRODUCT_DELETE: TypeAuthRoles = CMS_PERMISSION_PRODUCT_CREATE;
// /product/list/edit/{productId} CmsModule->ContainerComponent->ProductsComponent->ProductDetailsModule->
export const CMS_PERMISSION_PRODUCT_EDIT: TypeAuthRoles = CMS_PERMISSION_PRODUCTS;


// /product/pricing-settings  CmsModule->ContainerComponent->ProductsComponent->PricingSettingsComponent
export const CMS_PERMISSION_PRICING: TypeAuthRoles = [AuthPermissions.CMS_PRICING_UPDATE];
// no route
export const CMS_PERMISSION_PRICING_EDIT: TypeAuthRoles = [AuthPermissions.CMS_PRICING_UPDATE];

// /product/banner CmsModule->ContainerComponent->ProductsComponent->BannerModule->
export const CMS_PERMISSION_BANNER: TypeAuthRoles = ALlOWED;

// /product/seo/templates  CmsModule->ContainerComponent->ProductsComponent->SeoModule->TemplatesComponent
export const CMS_PERMISSION_SEO: TypeAuthRoles = []; // Todo: не рабочая //[AuthRoles.CMS_ADMIN];

// /product/pages  CmsModule->ContainerComponent->ProductsComponent->PageModule->ListComponent
export const CMS_PERMISSION_PAGES: TypeAuthRoles = [AuthRoles.CMS_ADMIN];
// /product/pages/create  CmsModule->ContainerComponent->ProductsComponent->PageModule->PageComponent
export const CMS_PERMISSION_PAGE_CREATE: TypeAuthRoles = CMS_PERMISSION_PAGES;
// no route
export const CMS_PERMISSION_PAGE_DELETE: TypeAuthRoles = CMS_PERMISSION_PAGE_CREATE;
// /product/pages/edit/{pageId}  CmsModule->ContainerComponent->ProductsComponent->PageModule->PageComponent
export const CMS_PERMISSION_PAGE_EDIT: TypeAuthRoles = CMS_PERMISSION_PAGES;


/** LOYALTY_MODULE */

// /loyalty
export const LOYALTY_MODULE_PERMISSION: TypeAuthRoles = [AuthRoles.SUPER_ADMIN];

/** LOGISTIC_MODULE */

// /logistic/map  LogisticModule->LogisticLayoutComponent
export const LOGISTIC_MODULE_PERMISSION: TypeAuthRoles = [AuthRoles.SUPER_ADMIN];
// /logistic/estimations LogisticModule->EstimationsComponent
export const LOGISTIC_PERMISSION_ESTIMATIONS: TypeAuthRoles = LOGISTIC_MODULE_PERMISSION;
// /logistic/couriers  LogisticModule->CouriersModule->LogisticLayoutComponent
export const LOGISTIC_PERMISSION_COURIERS: TypeAuthRoles = LOGISTIC_MODULE_PERMISSION;
// /logistic/couriers/create  LogisticModule->CouriersModule->CourierModule->CourierComponent
export const LOGISTIC_PERMISSION_COURIER_CREATE: TypeAuthRoles = LOGISTIC_MODULE_PERMISSION;
// no route and not used
export const LOGISTIC_PERMISSION_COURIER_DELETE: TypeAuthRoles = LOGISTIC_PERMISSION_COURIER_CREATE;
// /logistic/couriers/edit/{courierId}  LogisticModule->CouriersModule->CourierModule->CourierComponent
export const LOGISTIC_PERMISSION_COURIER_EDIT: TypeAuthRoles = LOGISTIC_MODULE_PERMISSION;

/** DELIVERY_MODULE */

// /delivery
export const DELIVERY_MODULE_PERMISSION: TypeAuthRoles = [AuthRoles.DELIVERY_ADMIN, AuthRoles.DELIVERY_MANAGER, AuthRoles.SUPER_ADMIN, AuthRoles.PARTNER];
// /delivery/kitchens | default route
export const DELIVERY_PERMISSION_VENUES: TypeAuthRoles = DELIVERY_MODULE_PERMISSION;
// /delivery/kitchens/create
export const DELIVERY_PERMISSION_VENUE_CREATE: TypeAuthRoles = [AuthPermissions.KITCHEN_CREATE, AuthRoles.SUPER_ADMIN, AuthRoles.PARTNER];
// /delivery/kitchens/edit/{kitchenId} // пока нету особой роли на редактирование кухни
export const DELIVERY_PERMISSION_VENUE_EDIT: TypeAuthRoles = [];

/** CRM_MODULE */

// /crm
export const CRM_MODULE_PERMISSION: TypeAuthRoles = [AuthRoles.SUPER_ADMIN];
// /crm/clients | default route
export const CRM_PERMISSION_CLIENTS: TypeAuthRoles = CRM_MODULE_PERMISSION;

/** OMS_MODULE */

// /oms OmsModule->OrdersComponent
export const OMS_MODULE_PERMISSION: TypeAuthRoles = [AuthRoles.OMS_ADMIN, AuthRoles.OMS_MANAGER];
// /oms/orders/ | default route | OmsModule->OrdersComponent
export const OMS_PERMISSION_ORDERS: TypeAuthRoles = OMS_MODULE_PERMISSION;
// /oms/order/{orderId} | OmsModule->OrderComponent
export const OMS_PERMISSION_ORDER: TypeAuthRoles = [AuthRoles.OMS_ADMIN, AuthRoles.OMS_MANAGER];
// /oms/order/{orderId}/client/{customerId} | OmsModule->OrderComponent
export const OMS_PERMISSION_CLIENT_EDIT: TypeAuthRoles = [AuthRoles.OMS_ADMIN, AuthRoles.OMS_MANAGER];
// /oms/order/{orderId}/edit/{productId} | OmsModule->ProductDetailsModule->LayoutProductComponent
export const OMS_PERMISSION_ORDER_PRODUCT: TypeAuthRoles = [...OMS_PERMISSION_ORDER, ...ALlOWED];

/** CONFIGURATIONS_MODULE */

// /configurations
export const CONFIGURATIONS_MODULE_PERMISSION: TypeAuthRoles = [AuthPermissions.INTEGRATION_CONFIG_UPDATE];


/** наличие любой роли у пользователя везде проверять только через эту функцию */
export function matchRoles(userRoles: TypeAuthRoles, allowedRoles: TypeAuthRoles = [], defIfEmpty = true): boolean {
  // // todo: quick fix, refactor then
  // let roles: TypeAuthRoles = [
  //   AuthRoles.SUPER_ADMIN,
  //   AuthRoles.PARTNER
  // ];

  return userRoles?.length ? userRoles.some((permission) => allowedRoles.length ? allowedRoles.some(role => permission === role) : true) : false;
}

export function pluckRouteRoles(snapshot: ActivatedRouteSnapshot): TypeAuthRoles {
  const {allowedRoles} = snapshot.data || {}
  return allowedRoles ?? [];
}
