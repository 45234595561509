import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService, matchRoles, pluckRouteRoles, TypeAuthRoles } from '@app/auth';
import { Nullable, OrganizationStorage } from '@app/shared';


export function CanActivateByAuthRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReturnType<CanActivateFn> {
  const _authService = inject(AuthService);
  const _organizationStorage = inject(OrganizationStorage);
  const _router = inject(Router);
  return _authService.me().pipe(
    map((tokenInfo) => {
      const organizations = Object.keys(tokenInfo.organizations.info);
      let orgId: Nullable<string> = organizations.length ? organizations[0] : null; //orgId
      if (!orgId || !organizations.includes(orgId)) {
        if (organizations.length > 1) {
          return _router.createUrlTree(['/organization'], {});
        } else {
          orgId = organizations[0];
          _organizationStorage.orgId = orgId;
        }
      }
      let roles = [] as any;
      if (orgId) {
        roles = [...tokenInfo?.organizations?.roles?.[orgId].roles, ...tokenInfo?.realm_access?.roles];
      } else {
        roles = tokenInfo?.realm_access?.roles;
      }
      const isPartner = (roles.includes("partner") && !roles.includes("super_admin"));
      
      if (orgId && !_authService.currentUserRolesSubject.value?.length) {
        _authService.currentUserRolesSubject.next(roles);
      }
      _organizationStorage.partnerId = isPartner ? tokenInfo.organizations.info[orgId].attributes.partnerId[0] : ''; // partnerId
      return matchRoles(roles, pluckRouteRoles(route), true);
    }),
    catchError(() => {
      // eslint-disable-next-line no-undef
      const redirectUrl = btoa(state.url);
      return of(_router.createUrlTree(['/dashboard'], {queryParams: {redirectUrl}}));
    }),
  );
}
