<app-layout></app-layout>
<mat-sidenav-container class="side-menu-container h-full d-flex direction-column" autosize>
  <mat-sidenav [class.open]="isExpanded" #sidenav class="example-sidenav h-full d-flex direction-column" mode="side" opened="true">
    <mat-nav-list *ngIf="(userRoles$ | async)?.length" class="links">

      <ng-container *ngFor="let menuItem of navMenu">
        <mat-list-item [matTooltipPosition]="'right'" [matTooltipClass]="'toolltip-style'" [matTooltip]="!isExpanded ? (menuItem.name | transloco) : ''" *ngIf="menuItem.isAccess$ | async">
          <a class="d-flex align-items-center h-11" [routerLink]="[menuItem.link]">
            <svg-util class="mx-1 mr-3" [path]="menuItem.iconPath" [viewBox]="menuItem.iconPath === 'product_nav.svg#product_nav' ? '0 0 24 16' : '0 0 24 24'" [ngClass]="{'prod-icon': menuItem.iconPath === 'product_nav.svg#product_nav'}"  [width]="'24'" [height]="'24'"></svg-util>
            <p [class.hidden]="!isExpanded" class="txt-route" mat-button>{{ menuItem.name | transloco }}</p>
          </a>
        </mat-list-item>
      </ng-container>
      

      <div class="d-flex bottom">
        <mat-list-item class="crisp-button crisp" *ngIf="!(superAdmin$ | async)" (click)="toggleCrispChat()">
          <div class="d-flex align-items-center justify-center h-11">
            <span class="material-symbols-outlined">
              chat
            </span>
          </div>
        </mat-list-item>
        <div class="links__language px-2" *ngIf="(language | async) as lang">
          <app-header-language class="w-full d-flex" [isExpanded]="isExpanded" [activeLanguage]="lang" (switchLanguageEvent)="switchLanguageHandler($event)"></app-header-language>
        </div>

        <mat-list-item [matTooltipPosition]="'right'" [matTooltipClass]="'toolltip-style'" [matTooltip]="!isExpanded ? ('NAVIGATION.LOGOUT' | transloco) : ''" (click)="logout()">
          <div class="d-flex align-items-center h-11">
            <svg-util class="mx-1 mr-3" [path]="'logout.svg#logout-svg'" [viewBox]="'0 0 24 24'" [width]="'24'" [height]="'24'"></svg-util>
            <p mat-button [class.hidden]="!isExpanded" class="txt-route">{{ 'NAVIGATION.LOGOUT' | transloco }}</p>
          </div>
        </mat-list-item>

      </div>
    </mat-nav-list>
  </mat-sidenav>
  <div class="h-full d-flex direction-column" (click)="hideNav()" [class.bg-expanded]="isExpanded">
    <router-outlet></router-outlet>
  </div>
  <app-spinner></app-spinner>
</mat-sidenav-container>
