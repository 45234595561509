import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'svg-util',
  templateUrl: './svg-util.component.html',
  styleUrls: ['./svg-util.component.scss'],
})
export class SvgUtilComponent implements OnInit {
  @Input() viewBox!: string;
  @Input() path!: string;
  @Input() width!: string;
  @Input() height!: string;
  @Input() fill! : string;
  @Input() fullPath = null;
  constructor() {}

  ngOnInit(): void {}
}
