import { ValidationErrors, ValidatorFn } from '@angular/forms';

export function PhoneValidator(): ValidatorFn {
  return (control): ValidationErrors | null => {
    const value: string = control.value;
    const allowed = /^[0-9]+$/.test(value);

    const validatorInstance = {
      invalidPhone: { value },
    };

    return !allowed ? validatorInstance : null;
  };
}
