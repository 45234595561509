import {
  APP_INITIALIZER,
  Inject,
  inject,
  Injectable,
  InjectionToken,
  Optional,
  PLATFORM_ID,
  Provider
} from '@angular/core';
import { NavigationError, Router, Event, NavigationEnd } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW, WINDOW_LOCAL_STORAGE } from '@lib/window';
import { filter, map } from 'rxjs/operators';
import { APP_VERSION } from '@lib/app-tokens';


export const APP_HARD_REFRESH = new InjectionToken<Function>('APP_HARD_REFRESH_TOKEN', {
  providedIn: 'root',
  factory: () => {
    const win = inject(WINDOW);
    return () => {
      if (win) {
        //eslint-disable-next-line no-self-assign
        win.location.href = win.location.href; // X-browser solution
      }
    }
  }
});

export const ROUTER_EVENTS = new InjectionToken<Observable<Event>>('Router events', {
  providedIn: 'root',
  factory: () => inject(Router, {optional: true})?.events ?? EMPTY
});


@Injectable({
  providedIn: 'root'
})
class APP_REFRESH_CHUNK_LOAD {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ROUTER_EVENTS) private events: Observable<Event>,
    @Inject(APP_HARD_REFRESH) private refresh: Function,
    @Inject(APP_VERSION) private appVersion: string,
    @Optional() @Inject(WINDOW_LOCAL_STORAGE) private localStorage: Storage,
  ) {
    if (this.events && this.localStorage && isPlatformBrowser(this.platformId)) {

      const navigationEndSub = events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => [
          this.appVersion !== this.localStorage.getItem('appVersion'),
          this.localStorage.getItem('chunkLoadError') !== '0'
        ])
      ).subscribe(([upVersion, notLocked]) => {
        if (upVersion) {
          this.localStorage.setItem('appVersion', this.appVersion);
        }
        if (notLocked) {
          this.localStorage.setItem('ChunkLoadError', '0');
        }
        navigationEndSub.unsubscribe();
      });

      const navigationErrorSub = events.pipe(
        filter(e => e instanceof NavigationError),
        filter(e => e && e.toString().indexOf('ChunkLoadError') > -1),
        map(e => [
          this.appVersion === this.localStorage.getItem('appVersion'),
          this.localStorage.getItem('chunkLoadError') !== '1'
        ])
      ).subscribe(([upVersion, notLocked]) => {
        if (upVersion && notLocked) {
          this.localStorage.setItem('ChunkLoadError', '1');
          navigationErrorSub.unsubscribe();
          this.refresh();
        }
      })
    }
  }
}

// https://stackoverflow.com/questions/44034039/angular-2-error-loading-chunk-failed?noredirect=1&lq=1

export const APP_REFRESH_CHUNK_LOAD_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: () => () => Promise.resolve(),
  deps: [APP_REFRESH_CHUNK_LOAD],
  multi: true,
}
