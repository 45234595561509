import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { SnackbarModule } from '@app/libs/snackbar';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { KitchenBookComponent } from '@app/delivery/components/kitchen-book/kitchen-book.component';
import { ToggleSelectComponent } from './components/toggle-select/toggle-select.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { SvgUtilComponent } from './components/svg-util/svg-util.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';

const MAT_MODULES = [
  TranslocoLocaleModule,
  TranslocoModule,
  MatSlideToggleModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonModule,
  MatInputModule,
  MatCardModule,
  MatTabsModule,
  MatCheckboxModule,
  MatRadioModule,
  MatIconModule,
  MatChipsModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTableModule,
  MatMenuModule,
];

@NgModule({
  declarations: [
    KitchenBookComponent,
    ToggleSelectComponent,
    SvgUtilComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TranslocoLocaleModule,
    TranslocoModule,
    SnackbarModule,
    ...MAT_MODULES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoLocaleModule,
    TranslocoModule,
    SnackbarModule,
    ...MAT_MODULES,
    KitchenBookComponent,
    SvgUtilComponent,
    ToggleSelectComponent
  ]
})
export class SharedModule {}
