import * as Sentry from '@sentry/browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, pluck, shareReplay, switchMap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService, CREDENTIALS_KEY, OrganizationStorage, Nullable, PartnerRepository, TypePartner } from '@app/shared';

import {
  CMS_MODULE_PERMISSION,
  CMS_PERMISSION_CATEGORIES,
  CMS_PERMISSION_CATEGORY_CREATE,
  CMS_PERMISSION_CATEGORY_DELETE,
  CMS_PERMISSION_CATEGORY_EDIT,
  CMS_PERMISSION_BRANDS,
  CMS_PERMISSION_BRAND_CREATE,
  CMS_PERMISSION_BRAND_DELETE,
  CMS_PERMISSION_BRAND_EDIT,
  CMS_PERMISSION_PAGES,
  CMS_PERMISSION_PAGE_CREATE,
  CMS_PERMISSION_PAGE_DELETE,
  CMS_PERMISSION_PAGE_EDIT,
  CMS_PERMISSION_PRICING,
  CMS_PERMISSION_PRICING_EDIT,

  OMS_MODULE_PERMISSION,
  OMS_PERMISSION_ORDERS,

  CRM_MODULE_PERMISSION,

  CONFIGURATIONS_MODULE_PERMISSION,

  LOYALTY_MODULE_PERMISSION,

  DELIVERY_MODULE_PERMISSION,
  DELIVERY_PERMISSION_VENUES,
  DELIVERY_PERMISSION_VENUE_CREATE,
  DELIVERY_PERMISSION_VENUE_EDIT,

  LOGISTIC_MODULE_PERMISSION,
  LOGISTIC_PERMISSION_COURIERS,
  LOGISTIC_PERMISSION_COURIER_EDIT,
  LOGISTIC_PERMISSION_COURIER_CREATE,
  LOGISTIC_PERMISSION_COURIER_DELETE,

  matchRoles,
  TypeAuthRoles,
  TypePermissions,
  CMS_PERMISSION_PRODUCTS,
  CMS_PERMISSION_PRODUCT_CREATE,
  CMS_PERMISSION_PRODUCT_DELETE, CMS_PERMISSION_PRODUCT_EDIT, SUPER_ADMIN_AUTHORISATION_ROLE, CMS_MODULE_ROOT_CATEGORY, CMS_MODULE_PARTNER_CATEGORY,
  SUPER_ADMIN_PERMISSION,
} from '../enums/auth-roles.enum';

import { IToken } from '../interfaces/credentials.interface';
import { ITokenInfo } from '../interfaces/token-info.interface';
import { KeycloakService } from 'keycloak-angular';
import { APP_HOST_URL } from '@lib/window';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserRolesSubject = new BehaviorSubject<TypeAuthRoles>([]);
  private readonly jwtHelper = new JwtHelperService();

  private _tokenInfo$ = new BehaviorSubject<ITokenInfo>(null!);
  public get tokenInfo(): ITokenInfo {
    return this._tokenInfo$.value!;
  }

  private partners!: TypePartner[];

  public get partnerList(): TypePartner[] {
    return this.partners;
  }
  public set setPartners(partners: Array<TypePartner> | null) {
    this.partners = partners || [];
  }
  public readonly currentUserRoles$: Observable<TypeAuthRoles> = this.currentUserRolesSubject.asObservable();

  public readonly cmsProductPermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, CMS_PERMISSION_PRODUCTS),
      canCreate: matchRoles(roles, CMS_PERMISSION_PRODUCT_CREATE),
      canDelete: matchRoles(roles, CMS_PERMISSION_PRODUCT_DELETE),
      canEdit: matchRoles(roles, CMS_PERMISSION_PRODUCT_EDIT),
    })),
    shareReplay(1)
  );
  public readonly superAdminPemision$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, SUPER_ADMIN_PERMISSION)
    })),
    shareReplay(1)
  );

  public readonly cmsCategoryPermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, CMS_PERMISSION_CATEGORIES),
      canCreate: matchRoles(roles, CMS_PERMISSION_CATEGORY_CREATE),
      canDelete: matchRoles(roles, CMS_PERMISSION_CATEGORY_DELETE),
      canEdit: matchRoles(roles, CMS_PERMISSION_CATEGORY_EDIT),
    })),
    shareReplay(1)
  );

  public readonly cmsBrandPermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, CMS_PERMISSION_BRANDS),
      canCreate: matchRoles(roles, CMS_PERMISSION_BRAND_CREATE),
      canDelete: matchRoles(roles, CMS_PERMISSION_BRAND_DELETE),
      canEdit: matchRoles(roles, CMS_PERMISSION_BRAND_EDIT),
    })),
    shareReplay(1)
  );

  public readonly cmsPagesPermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, CMS_PERMISSION_PAGES),
      canCreate: matchRoles(roles, CMS_PERMISSION_PAGE_CREATE),
      canDelete: matchRoles(roles, CMS_PERMISSION_PAGE_DELETE),
      canEdit: matchRoles(roles, CMS_PERMISSION_PAGE_EDIT),
    })),
    shareReplay(1)
  );

  public readonly cmsPricingPermissions$ = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, CMS_PERMISSION_PRICING),
      canEdit: matchRoles(roles, CMS_PERMISSION_PRICING_EDIT),
    })),
    shareReplay(1)
  );

  public readonly categoryRootAccess$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => {
      const sada = {
        canRead: matchRoles(roles, CMS_MODULE_ROOT_CATEGORY),
        canCreate: matchRoles(roles, CMS_MODULE_ROOT_CATEGORY),
        canDelete: matchRoles(roles, CMS_MODULE_ROOT_CATEGORY),
        canEdit: matchRoles(roles, CMS_MODULE_ROOT_CATEGORY),
      }
      return sada;
    }),
    shareReplay(1)
  );
  public readonly categoryPartnerAccess$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, CMS_MODULE_PARTNER_CATEGORY),
      canCreate: matchRoles(roles, CMS_MODULE_PARTNER_CATEGORY),
      canDelete: matchRoles(roles, CMS_MODULE_PARTNER_CATEGORY),
      canEdit: matchRoles(roles, CMS_MODULE_PARTNER_CATEGORY),
    })),
    shareReplay(1)
  );

  public readonly cmsAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, CMS_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly crmAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, CRM_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly omsAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, OMS_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly logisticAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, LOGISTIC_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly loyaltyAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, LOYALTY_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly partnersAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, SUPER_ADMIN_PERMISSION)),
    shareReplay(1)
  );

  public readonly canChangeConfigurations$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, CONFIGURATIONS_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly deliveryAccess$ = this.currentUserRoles$.pipe(
    map((roles) => matchRoles(roles, DELIVERY_MODULE_PERMISSION)),
    shareReplay(1)
  );

  public readonly omsOrderPermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, OMS_PERMISSION_ORDERS),
    })),
    shareReplay(1)
  );

  public readonly deliveryVenuePermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, DELIVERY_PERMISSION_VENUES),
      canCreate: matchRoles(roles, SUPER_ADMIN_PERMISSION),
      canEdit: matchRoles(roles, DELIVERY_PERMISSION_VENUE_EDIT),
    })),
    shareReplay(1)
  );

  public readonly logisticCourierPermissions$: Observable<TypePermissions> = this.currentUserRoles$.pipe(
    map((roles) => ({
      canRead: matchRoles(roles, LOGISTIC_PERMISSION_COURIERS),
      canCreate: matchRoles(roles, LOGISTIC_PERMISSION_COURIER_CREATE),
      canDelete: matchRoles(roles, LOGISTIC_PERMISSION_COURIER_DELETE),
      canEdit: matchRoles(roles, LOGISTIC_PERMISSION_COURIER_EDIT),
    })),
    shareReplay(1)
  );


  constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _organizationStorage: OrganizationStorage,
    private _keycloakService: KeycloakService,
    private _partnerRepository: PartnerRepository,
    @Inject(APP_HOST_URL) protected readonly _appHostUrl: string,
  ) { }

  public me(): Observable<ITokenInfo> {
    try {
      const { access_token } = this._localStorageService.get<IToken>(CREDENTIALS_KEY) || {};
      const tokenInfo = this.jwtHelper.decodeToken<ITokenInfo>(access_token);
      this._tokenInfo$.next(tokenInfo);
      if (!tokenInfo) {
        Sentry.setUser(null);
      }

      Sentry.setUser({
        id: tokenInfo.sub,
        sid: tokenInfo.sid,
        email: tokenInfo.email,
        display: tokenInfo.name,
        username: tokenInfo.preferred_username,
      });

      return of(tokenInfo)
        .pipe(
          switchMap(info => {
            if (info.realm_access.roles.includes(SUPER_ADMIN_AUTHORISATION_ROLE)) { // проверяем на супер-админа
              if (this.partners) { // проверка, чтобы лишний раз не делать запрос на сервер
                this.mergeRoles(info);
                return of(info);
              } else {
                return this._partnerRepository.partners().pipe(
                  pluck('data'),
                  map(partners => {
                    this.partners = partners;
                    this.mergeRoles(info);
                    return info;
                  }));
              }
            } else {
              this.mergeRoles(info);
              return of(info)
            }
          })
        );
    } catch (e) {
      return throwError(e);
    }
  }

  // объеденяет роли пользователя из оганизации и realm_access
  private mergeRoles(info: ITokenInfo): void {
    const organizations = Object.keys(info.organizations.info);
    this._organizationStorage.orgId = organizations[0];
    const orgId: Nullable<string> = organizations[0];
    if (orgId && organizations.includes(orgId!)) {
      const mergedRoles = [...info?.organizations?.roles?.[orgId].roles, ...info?.realm_access?.roles] as TypeAuthRoles;
      this.currentUserRolesSubject.next(mergedRoles);
    } else {
      const mergedRoles = info?.realm_access?.roles as TypeAuthRoles;
      this.currentUserRolesSubject.next(mergedRoles);
    }

  }

  public logout(): void {
    this.currentUserRolesSubject.next([]);
    this._localStorageService.remove(CREDENTIALS_KEY);
    this._organizationStorage.clear();
    this._keycloakService.logout(this._appHostUrl);
  }

}
