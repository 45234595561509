import { PaymentMethods } from '../enums';

export function normalizeOrderPaymentMethod(method: PaymentMethods | undefined): string {
  let normalizedMethod: string;

  switch (method) {
    case PaymentMethods.CASH:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.CASH';
      break;
    case PaymentMethods.CREDIT_CARD:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.CREDIT_CARD';
      break;
    case PaymentMethods.APPLE_PAY:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.APPLE_PAY';
      break;
    case PaymentMethods.GOOGLE_PAY:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.GOOGLE_PAY';
      break;
    case PaymentMethods.CARD_ON_DELIVERY:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.CARD_ON_DELIVERY';
      break;
    case PaymentMethods.BLIK:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.BLIK';
      break;
    default:
      normalizedMethod = 'SHARED.PAYMENT_METHODS.DEFAULT';
  }

  return normalizedMethod;
}
