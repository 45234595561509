import { NgModule } from '@angular/core';
import { LayoutCreateButtonComponent } from './layout-create-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

const MATERIAl = [MatButtonModule, MatIconModule];

@NgModule({
  declarations: [
    LayoutCreateButtonComponent
  ],
  imports: [
    MATERIAl,
    RouterModule,
  ],
  exports: [
    LayoutCreateButtonComponent,
  ],
})
export class LayoutCreateButtonModule { }
