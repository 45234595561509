<div class="toaster-container" [@toasterAnimation]="toasters$ | async">
  <a (click)="close(toaster.id)" [routerLink]="toaster.redirectTo" *ngFor="let toaster of toasters$ | async"
    class="toaster top-right" [ngClass]="{'cursor-pointer': toaster?.redirectTo}">
    <div class="message" [innerHTML]="toaster.message"></div>
    <button class="close-btn" (click)="$event.preventDefault(); close(toaster.id)">
      <span class="material-symbols-outlined">
        close
      </span>
    </button>
  </a>
</div>