import { NgModule } from '@angular/core';

import { MatSidenavModule } from '@angular/material/sidenav';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@app/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SentryProviders } from 'src/sentry';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocaleHeadersInterceptor } from './interceptors/locale-headers.interceptor';
import { HttpRequestWithoutCacheInterceptor } from './interceptors/HttpRequestWithoutCache.interceptor';
import { HttpRequestTimeoutInterceptor } from './interceptors/HttpRequestTimeout.interceptor';
import { APP_REFRESH_CHUNK_LOAD_PROVIDER } from '@lib/hardRouter';
import { MatListModule } from '@angular/material/list';
import { HeaderLanguageModule } from '@shared/components/header-language';
import { SharedModule } from './shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MainContainerComponent } from './main/container/main-container/main-container.component';
import { AuthConfigModule } from './auth/auth.config.module';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { envProviders, NavRouterInitializer } from '@app/shared';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToasterModule } from '@shared/components/toaster';
import { ClickOutsideModule } from 'ng-click-outside';
import { LayoutModule } from '@shared/components/layout';

const maskConfig: Partial<IConfig> = {
  validation: true
};


const MATERIAL = [
  MatSidenavModule,
  MatButtonModule,
  MatTooltipModule,
  MatListModule,
  MatIconModule,
  MatChipsModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [
    AppComponent,
    MainContainerComponent
  ],
  imports: [
    CoreModule,
    AuthConfigModule,
    HeaderLanguageModule,
    ClickOutsideModule,
    ToasterModule,
    LayoutModule,
    SharedModule,
    AppRoutingModule,
    MATERIAL,
  ],
  providers: [
    envProviders,
    provideEnvironmentNgxMask(maskConfig),
    ...SentryProviders,
    APP_REFRESH_CHUNK_LOAD_PROVIDER,
    NavRouterInitializer,
    { provide: HTTP_INTERCEPTORS, useClass: LocaleHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestWithoutCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestTimeoutInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
