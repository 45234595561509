import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ToasterService } from '@shared/components/toaster/toaster.service';

@Component({
  selector:'alert-mute',
  templateUrl: './alert-mute.component.html',
  styleUrls: ['./alert-mute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
 })
export class AlertMuteComponent {

  public alertMute = this.toastService.alertMuted;

  constructor(
    private readonly toastService: ToasterService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {}

  public alertMuteToggle(): void {
    this.alertMute = this.toastService.alertMuteToggle();
    this._cdRef.detectChanges();
  }
}
