<ng-container *ngIf="inline; else select">
  <div class="language-inline">
    <ng-template ngFor [ngForOf]="languages | keyvalue" let-language>
      <button
        mat-button
        (click)="switchLanguage(language.key)"
        [class.language-inline__button--active]="activeLanguage === language.key"
        class="language-inline__button"
      >{{ language.key | uppercase }}</button>
    </ng-template>
  </div>
</ng-container>

<ng-template #select>
  <button mat-icon-button class="btn-select d-flex justify-center p-0 align-items-center" (click)="mtselect.open()" *ngIf="!isExpanded"><span class="text-size-8">{{activeLanguage.toUpperCase()}}</span></button>

  <div class="language w-full small-field forms-position-bottom"  [class.hidden]="!isExpanded">
    <mat-form-field appearance="outline" class="w-full border-radius-none">
      <mat-select  #mtselect [(ngModel)]="activeLanguage" (selectionChange)="switchLanguage($event.value)">
        <mat-option *ngFor="let language of languages | keyvalue" [value]="language.key">
          {{ languageMap[language.key] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
