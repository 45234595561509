import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackbarService } from './services/snackbar.service';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarAlertComponent } from './components/snackbar-alert/snackbar-alert.component';
import {MatButtonModule} from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SnackbarAlertComponent,
  ],
  exports:[],
  providers: [SnackbarService],
  imports: [CommonModule, MatIconModule, MatSnackBarModule, MatButtonModule, RouterModule],
})
export class SnackbarModule {}
