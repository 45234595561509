import { inject, InjectionToken, StaticProvider } from '@angular/core';
import { environment } from 'environment';
import { APP_VERSION, DEBUG_ENABLE } from '@lib/app-tokens';
import { appDefaultConfig } from '../../../environments/app.config';
import { HTTP_REQUEST_TIMEOUT } from '@app/interceptors/HttpRequestTimeout.interceptor';

/** use  @Inject(ENVIRONMENT) private readonly env: Configuration */
export const ENVIRONMENT: InjectionToken<Configuration> = new InjectionToken<Configuration>('ENVIRONMENT_TOKEN');
export const IS_BUILD_PRODUCTION_MODE: InjectionToken<boolean> = new InjectionToken<boolean>('IS_BUILD_PRODUCTION_MODE_TOKEN');
export const IS_PRODUCTION_ENV: InjectionToken<boolean> = new InjectionToken<boolean>('IS_PRODUCTION_ENV_TOKEN');
export const ENV_NAME: InjectionToken<boolean> = new InjectionToken<boolean>('ENV_NAME_TOKEN');
export const APP_RESIZE: InjectionToken<{mobile: number, tablet: number, debounce: number}> = new InjectionToken<{mobile: number, tablet: number, debounce: number}>('APP_RESIZE_TOKEN');
export const ANTISTATIC: InjectionToken<string> = new InjectionToken<string>('ANTISTATIC_TOKEN');

export const APP_PLATFORM: InjectionToken<string> = new InjectionToken<string>('APP_PLATFORM_TOKEN');
export const API_GATEWAY_URL: InjectionToken<string> = new InjectionToken<string>('API_GATEWAY_URL_TOKEN');
export const API_UPLOAD_URL: InjectionToken<string> = new InjectionToken<string>('API_UPLOAD_URL_TOKEN');
export const PUSHER_AUTH_KEY: InjectionToken<string> = new InjectionToken<string>('PUSHER_AUTH_KEY_TOKEN');
export const PUSHER_SUPER_CHANNEL: InjectionToken<string> = new InjectionToken<string>('PUSHER_SUPER_CHANNEL_TOKEN');
export const KEYCLOAK_CFG: InjectionToken<Configuration['keycloak']> = new InjectionToken<Configuration['keycloak']>('KEYCLOAK_CFG_TOKEN');
export const GOOGLE_MAPS_KEY: InjectionToken<string> = new InjectionToken<string>('GOOGLE_MAPS_KEY_TOKEN');
export const GOOGLE_MAPS_DEFAULT_LOCATION: InjectionToken<{ latitude: number, longitude: number }> = new InjectionToken<{ latitude: number, longitude: number }>('GOOGLE_MAPS_DEFAULT_LOCATION_TOKEN');
export const APP_PRODUCT_IMAGE_UPLOAD_CFG: InjectionToken<ProductUploadImageCfg> = new InjectionToken<ProductUploadImageCfg>('APP_PRODUCT_IMAGE_UPLOAD_CFG_TOKEN');

export type ProductUploadImageCfg = { size: number, height: number, width: number };
type PermissionOption = { enable: boolean };
type NgOptions = {
  ng: {
    buildModeProduction: boolean,
    customConfig: { url: string, errorPage: { url: string, message: string }, errorLevel: number }
  }
}

type EnvOptions = {
  env: {
    isProduction: boolean,
    name: string,
    maintenance: { url: string, message: string, recheckInterval: string | number } & PermissionOption
    browser: { debug: PermissionOption },
  }
}

export type Configuration = NgOptions & EnvOptions & {
  http: {
    browser: { debug: PermissionOption },
    request: { timeout: number, withoutCache: PermissionOption }
  },
  app: {
    name: string,
    version: string,
    google: { maps: { key: string, location: { latitude: number, longitude: number } } },
    resize: { mobile: number, tablet: number, debounce: number },
    cookie: { prefix: string, contextId: string },
    product: {
      uploadImage: ProductUploadImageCfg,
    }
  },
  gateway: {
    browser: { host: string, ssl: boolean, origin: string },
    upload: { host: string, ssl: boolean, origin: string },
  },
  sentry: {
    browser: { breadcrumbs: { console: boolean; }, dsn: string } & PermissionOption,
  },
  pusher: {
    auth: {
      key: string,
    },
    super: {
      channel: string
    }
  },
  keycloak: {
    url: string,
    realm: string,
    clientId: string,
  },
}

export function antistatic(prefix: string = '?'): string {
  return '' + prefix + Math.random().toString(36).substr(2, 9);
}

export function useOriginUrl(cfg: { ssl: boolean, host: string }): string {
  return new URL(`http${cfg?.ssl ? 's:' : ':'}//${cfg?.host}`)?.origin;
}

export function globalEnv(isBrowser: boolean = false): Readonly<Configuration> {

  type EnvType = { [p: string]: boolean | string | any };

  const mapEnvToConfiguration = (e: EnvType, acc = {}): Configuration => {
    Object.entries(e).map(([signature, value]) => {
      const keys: Array<string> = signature.split('.');
      const last = keys.pop() as string;
      keys.reduce((o: { [p: string]: any }, k) => o[k] = o[k] || {}, acc)[last] = value;
    });
    return acc as Configuration;
  }

  function correctionGateway(cfg: Configuration): Configuration {
    Object.entries(cfg?.gateway).forEach(([key, value]) => {
      if (value) {
        const {ssl, origin, host} = value;
        if (!host && origin) {
          const Url = new URL(origin);
          cfg = mapEnvToConfiguration({[`gateway.${key}.host`]: Url.host}, cfg);
          cfg = mapEnvToConfiguration({[`gateway.${key}.ssl`]: Url.protocol === 'https:'}, cfg);
        } else if (host && !origin) {
          const Url = new URL(useOriginUrl({ssl, host}));
          cfg = mapEnvToConfiguration({[`gateway.${key}.origin`]: Url.origin}, cfg);
        }
      }
    });
    return cfg;
  }

  function correctionDebug(cfg: Configuration): Configuration {
    if (isBrowser && !cfg?.env?.browser?.debug?.enable) {
      // eslint-disable-next-line no-undef
      if (localStorage && localStorage.getItem('debug') === 'debug') {
        cfg = mapEnvToConfiguration({'env.browser.debug.enable': true}, cfg);
      }
    }
    return cfg;
  }

  function logEnv(cfg: Configuration): void {
    if (isBrowser && cfg?.env?.browser?.debug?.enable) {
      // eslint-disable-next-line no-undef,no-console
      console.log('ENVIRONMENT', cfg);
    }
  }

  let cfg: Configuration = mapEnvToConfiguration(appDefaultConfig); // first - set default
  cfg = mapEnvToConfiguration(environment, cfg);// second - override default value for build config
  cfg = correctionGateway(cfg);
  cfg = correctionDebug(cfg);

  logEnv(cfg);
  return cfg;
}

export const envExtraProviders: Array<StaticProvider> = [
  {provide: IS_BUILD_PRODUCTION_MODE, useFactory: () => inject(ENVIRONMENT)?.ng?.buildModeProduction},
  {provide: IS_PRODUCTION_ENV, useFactory: () => inject(ENVIRONMENT)?.env?.isProduction},
  {provide: ENV_NAME, useFactory: () =>  inject(ENVIRONMENT)?.env?.name},
  {provide: APP_VERSION, useFactory: () => inject(ENVIRONMENT)?.app?.version},
  {provide: DEBUG_ENABLE, useFactory: () =>inject(ENVIRONMENT)?.env?.browser?.debug?.enable || false},
  {provide: API_GATEWAY_URL, useFactory: () => inject(ENVIRONMENT)?.gateway?.browser?.origin},
  {provide: API_UPLOAD_URL, useFactory: () => inject(ENVIRONMENT)?.gateway?.upload?.origin},
];

export const envProviders: Array<StaticProvider> = [
  {provide: HTTP_REQUEST_TIMEOUT, useFactory: () => inject(ENVIRONMENT)?.http?.request?.timeout},
  {provide: PUSHER_AUTH_KEY, useFactory: () => inject(ENVIRONMENT)?.pusher?.auth?.key},
  {provide: PUSHER_SUPER_CHANNEL, useFactory: () => inject(ENVIRONMENT)?.pusher?.super?.channel},
  {provide: KEYCLOAK_CFG, useFactory: () => inject(ENVIRONMENT)?.keycloak},
  {provide: GOOGLE_MAPS_KEY, useFactory: () => inject(ENVIRONMENT)?.app?.google?.maps?.key},
  {provide: GOOGLE_MAPS_DEFAULT_LOCATION, useFactory: () => inject(ENVIRONMENT)?.app?.google?.maps?.location},
  {
    provide: APP_PRODUCT_IMAGE_UPLOAD_CFG, useFactory: () => {
      const {width, height, size} = inject(ENVIRONMENT)?.app?.product?.uploadImage;
      return {width: width ?? 2500, height: height ?? 2500, size: (size ?? 5) * 1024 * 1024};
    }
  },
  {provide: APP_PLATFORM, useFactory: () => inject(ENVIRONMENT)?.app?.name},
  {provide: ANTISTATIC, useFactory: () => antistatic()},
  {
    provide: APP_RESIZE, useFactory: () => {
      const {mobile, tablet, debounce} = inject(ENVIRONMENT)?.app?.resize || {};
      return {mobile: mobile || 768, tablet: tablet || 1200, debounce: debounce || 1000};
    }
  },
  // {provide: APP_COOKIE_PREFIX, useFactory: () => inject(ENVIRONMENT)?.app?.cookie?.prefix || 'app'},
  // {provide: APP_COOKIE_CONTEXT_ID, useFactory: () => inject(ENVIRONMENT)?.app?.cookie?.contextId || ''}
];
