import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MainContainerComponent } from './main/container/main-container/main-container.component';
import { CanActivateByAuthRole } from '@app/core';
import {
  CRM_MODULE_PERMISSION,
  CMS_MODULE_PERMISSION,
  OMS_MODULE_PERMISSION,
  DELIVERY_MODULE_PERMISSION,
  LOGISTIC_MODULE_PERMISSION,
  LOYALTY_MODULE_PERMISSION,
  CONFIGURATIONS_MODULE_PERMISSION,
  SUPER_ADMIN_PERMISSION,
} from '@app/auth';

const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    canActivate: [AuthGuard],
    canActivateChild: [CanActivateByAuthRole],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./insights/insights.module').then((m) => m.InsightsModule)
      },
      {
        path: 'configurations',
        data: {
          allowedRoles: CONFIGURATIONS_MODULE_PERMISSION,
        },
        loadChildren: () => import('./configurations/configurations.module').then((m) => m.ConfigurationsModule)
      },
      {
        path: 'clients',
        data: {
          allowedRoles: CRM_MODULE_PERMISSION,
        },
        loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
      },
      {
        path: 'orders',
        data: {
          allowedRoles: CMS_MODULE_PERMISSION,
        },
        loadChildren: () => import('./oms/oms.module').then((m) => m.OmsModule),
      },
      {
        path: 'product',
        data: {
          allowedRoles: CMS_MODULE_PERMISSION,
        },
        loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
      },
      // {
      //   path: 'clients',
      //   data: {
      //     allowedRoles: CMS_MODULE_PERMISSION,
      //   },
      //   loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule),
      // },
      {
        path: 'loyalty',
        data: {
          allowedRoles: LOYALTY_MODULE_PERMISSION,
        },
        loadChildren: () => import('./loyalty/loyalty.module').then((m) => m.LoyaltyModule),
      },
      {
        path: 'logistic',
        data: {
          allowedRoles: LOGISTIC_MODULE_PERMISSION,
        },
        loadChildren: () => import('./logistic/logistic.module').then((m) => m.LogisticModule),
      },
      {
        path: 'partners',
        data: {
          allowedRoles: SUPER_ADMIN_PERMISSION,
        },
        loadChildren: () => import('./partners/partners.module').then((m) => m.PartnersModule),
      },
      {
        path: 'delivery',
        data: {
          allowedRoles: DELIVERY_MODULE_PERMISSION,
        },
        loadChildren: () => import('./delivery/delivery.module').then((m) => m.DeliveryModule),
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true, /** todo: use @Input('entityId') decorator for entity fields */
      enableTracing: false /** for router debug */
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
