import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './toaster.component';
import { RouterModule } from '@angular/router';
import { BackSaveModule } from '../back-save';
import { ToasterService } from './toaster.service';
import { ClickOutsideModule } from 'ng-click-outside';

/** @deprecated */
@NgModule({
  declarations: [
    ToasterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BackSaveModule,
    ClickOutsideModule
  ],
  exports: [
    ToasterComponent,
  ],
  providers: [ToasterService]
})
export class ToasterModule { }
