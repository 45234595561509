
import {
  Injectable
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from './local-storage.service';
import {
  registerLocaleData
} from '@angular/common';
import localeUA from '@angular/common/locales/uk';
import localeEN from '@angular/common/locales/es';
import localeRU from '@angular/common/locales/ru';
import localeUAExtra from '@angular/common/locales/extra/uk';
import localeENExtra from '@angular/common/locales/extra/en';
import localeRUExtra from '@angular/common/locales/extra/ru';
import { BehaviorSubject } from 'rxjs';

export const _APP_SUPPORTED_LANGUAGES: any = {
  'en': {
    register: () => registerLocaleData(localeEN, 'en-US', localeENExtra),
  },
  'uk': {
    register: () => registerLocaleData(localeUA, 'uk', localeUAExtra),
  },
  'ru': {
    register: () => registerLocaleData(localeRU, 'ru', localeRUExtra),
  }
};


@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private _lang$ = new BehaviorSubject<string>('');
  public get lang(): string {
    return this._lang$.value!;
  }

  constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _translocoService: TranslocoService,
  ) {}

  protected registerLocaleData(lang: string) {
    if (_APP_SUPPORTED_LANGUAGES[lang]?.register) {
      _APP_SUPPORTED_LANGUAGES[lang].register();
    }
  }

  public getCurrentLocale(): string | null {
    return this._localStorageService.get<string>('Accept-Language');
  }

  public setLocale(locale: string): void {
    this.registerLocaleData(locale);
    // eslint-disable-next-line no-undef
    document.documentElement.setAttribute('lang', locale);
    this._localStorageService.set<string>('Accept-Language', locale);
    this._translocoService.setActiveLang(locale);
    this._lang$.next(locale)
  }
}
