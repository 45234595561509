import { ILanguage } from '@app/core/interfaces/language.interface';

export class LanguageConfig {

  static readonly languages: ILanguage = {
    uk: {
      lang: 'uk',
      ogLocale: 'uk_UA'
    },
    ru: {
      lang: 'ru',
      ogLocale: 'ru_RU'
    },
    en: {
      lang: 'en',
      ogLocale: 'en-US'
    }
  };

}
