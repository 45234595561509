import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  API_GATEWAY_URL,
  TypeReferralSettings,
  TypeResponseData,
  TypeResponsePaginated,
  TypeCashbackHistoryItem,
  Entities,
  TypeCashbackSettings,
  TypeCashback,
  TypePromoCode,
  EntityCreate,
  EntityPut,
  ID,
  TypePromoCodeFilters,
  TypeCashbackHistoryFilters
} from '@app/shared';


function correctionDigitsAfterComma(value: string, max = 2): string {
  return Number(value).toFixed(max);
}

function correctValuesToFixed(data: TypeReferralSettings): TypeReferralSettings {
  Object.keys(data).forEach((key: string) => {
    const typedKey = key as keyof TypeReferralSettings;
    data[typedKey] = correctionDigitsAfterComma(data[typedKey]);
  });

  return data;
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/loyalty';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[USE] GET    /v1/admin/loyalty/cashback/balance/{customerId} Баланс по кешбеку пользователя
  public getCashback(customerId: string): Observable<TypeResponseData<TypeCashback>> {
    return this.get<TypeResponseData<TypeCashback>>(`cashback/balance/${customerId}`);
  }

  //[USE] POST   /v1/admin/loyalty/cashback/balance/{customerId}/edit Изменение баланса кешбека пользователя
  public updateCashback(cashback: TypeCashback): Observable<TypeResponseData<TypeCashback>> {
    const {customerId} = cashback;
    return this.post<TypeResponseData<TypeCashback>, TypeCashback>(`cashback/balance/${customerId}/edit`, cashback);
  }

  //[USE] GET    /v1/admin/loyalty/cashback/history Информация о кешбеках пользователей [DOC] todo: pagination
  public getCashbackHistory(options: TypeCashbackHistoryFilters): Observable<TypeResponsePaginated<Entities<TypeCashbackHistoryItem>>> {
    const {page, limit, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams, {skipEmpty: true});
    return this.get<TypeResponsePaginated<Entities<TypeCashbackHistoryItem>>>('cashback/history', params); //Verify pagination is not supported
  }

  //[USE] GET    /v1/admin/loyalty/cashback/settings Настройки по начислению кешбека
  public getCashbackSettings(): Observable<TypeResponseData<TypeCashbackSettings>> {
    return this.get<TypeResponseData<TypeCashbackSettings>>('cashback/settings');
  }

  //[USE] PUT    /v1/admin/loyalty/cashback/settings Изменение настроек по начислению кешбека
  public updateCashbackSettings(cashback: TypeCashbackSettings): Observable<TypeResponseData<TypeCashbackSettings>> {
    return this.put<TypeResponseData<TypeCashbackSettings>, TypeCashbackSettings>('cashback/settings', cashback);
  }

  //[USE] GET    /v1/admin/loyalty/promo Список промо [DOC] todo: pagination
  public getPromoCodes(options: TypePromoCodeFilters): Observable<TypeResponsePaginated<Entities<TypePromoCode>>> {
    const {page, limit, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams); //filter isEnabled
    return this.get<TypeResponsePaginated<Entities<TypePromoCode>>>('promo', params); //Verify pagination is not supported
  }

  //[USE] POST   /v1/admin/loyalty/promo Создать промо
  public createPromoCode(promo: EntityCreate<TypePromoCode>): Observable<TypeResponseData<TypePromoCode>> {
    return this.post<TypeResponseData<TypePromoCode>, EntityCreate<TypePromoCode>>('promo', promo);
  }

  //[USE] GET    /v1/admin/loyalty/promo/{id} Информация о промо
  public getPromoCode(id: ID<TypePromoCode>): Observable<TypeResponseData<TypePromoCode>> {
    return this.get<TypeResponseData<TypePromoCode>>(`promo/${id}`);
  }

  //[USE] PUT    /v1/admin/loyalty/promo/{id} Изменить промо
  public updatePromoCode(id: ID<TypePromoCode>, promo: EntityPut<TypePromoCode>): Observable<TypeResponseData<TypePromoCode>> {
    return this.put<TypeResponseData<TypePromoCode>, EntityPut<TypePromoCode>>(`promo/${id}`, promo);
  }

  //[USE] DELETE /v1/admin/loyalty/promo/{id} Удалить промо
  public deletePromoCode(id: ID<TypePromoCode>): Observable<never> {
    return this.delete<never>(`promo/${id}`);
  }

  //[USE] GET    /v1/admin/loyalty/promo/by-code/{code} Получение промо по коду
  public promoByCode(code: string): Observable<TypeResponseData<TypePromoCode>> {
    return this.get<TypeResponseData<TypePromoCode>>(`promo/by-code/${code}`);
  }

  //[USE] GET    /v1/admin/loyalty/referral/settings Настройки по реферальной программе
  public getReferralSetting(): Observable<TypeReferralSettings> {
    return this.get<TypeResponseData<TypeReferralSettings>>('referral/settings').pipe(
      pluck('data'),
      map(correctValuesToFixed),
    )
  }

  //[USE] PUT    /v1/admin/loyalty/referral/settings Изменение настроек по реферальной программе
  public putReferralSetting(value: TypeReferralSettings): Observable<TypeReferralSettings> {
    return this.put<TypeResponseData<TypeReferralSettings>, TypeReferralSettings>('referral/settings', {...value}).pipe(
      pluck('data'),
      map(correctValuesToFixed),
    )
  }
}
