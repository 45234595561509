import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import {Howl} from 'howler';
import { SnackbarAlertComponent } from '../components/snackbar-alert/snackbar-alert.component';

@Injectable()
export class SnackbarService {

  private _config: MatSnackBarConfig = {
    duration: 4000,
    panelClass: ['success-snackbar'],
  };

  private _errorConfig: MatSnackBarConfig = {
    duration: 4000,
    panelClass: ['warning-snackbar'],
  };

  private _alertConfig: MatSnackBarConfig = {
    duration: 40000,
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: ['alert-snackbar'],
  };

  private _alertSound = new Howl({src: ['/assets/sound/alert.mp3']});

  public alertMuted = true;

  public alertMuteToggle(): boolean {
    this._alertSound.mute(!this.alertMuted);
    this.alertMuted = !this.alertMuted;
    return this.alertMuted;
  }

  private _successMessage = '';
  private okButtonMessage = '';
  private _errorMessage = '';

  snackBarRef!: MatSnackBarRef<any>;
  snackBarList: { message: string }[] = []

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _translocoService: TranslocoService,
  ) {
    this._alertSound.mute(this.alertMuted);
    this._alertSound.on('playerror', () => {
      this._alertSound.once('unlock', () => {
        this._alertSound.play();
      });
  });
    _translocoService.events$.subscribe(events => {
      if (events.type === 'translationLoadSuccess') {
        this._initDefaultSnackbarMessages();
      }
    });
  }

  private next(): void {
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.snackBarList.splice(0, 1);
      if (this.snackBarList.length) {
        this.snackBarRef = this._snackBar.open(this.snackBarList[0].message, this.okButtonMessage, this._config);
      }
    });
  }

  public setSuccessMessage(successMessageKey: string, needTranslate = true,): this {
    if (needTranslate) {
      this._successMessage = this._translocoService.translate<string>(successMessageKey);
    } else {
      this._successMessage = successMessageKey;
    }
    return this;
  }

  public setOkButtonLabel(okButtonLabelKey: string, needTranslate = true): void {
    if (needTranslate) {
      this.okButtonMessage = this._translocoService.translate<string>(okButtonLabelKey);
    } else {
      this.okButtonMessage = okButtonLabelKey;
    }
  }

  public setErrorMessage(errorMessageKey: string, needTranslate = true): void {
    if (needTranslate) {
      this._errorMessage = this._translocoService.translate<string>(errorMessageKey);
    } else {
      this._errorMessage = errorMessageKey;
    }
  }

  public success(message?: string, config?: MatSnackBarConfig): void {
    const successMessage = message ?? (this._successMessage.length ? this._successMessage : this._translocoService.translate<string>('PRODUCTS.INFO_TAB.SNACKBAR.FORM.SAVE_SUCCESS'));
    if (!this.snackBarList.length) {
      this.snackBarRef = this._snackBar.open(successMessage, this.okButtonMessage, config ? config : this._config);
    }
    this.snackBarList.push({message: successMessage});

    this.next();
  }

  public alert(message: string, link?: string): void {
    setTimeout(() => {
      document.body.focus();
      this._alertSound.play();
    }, 10);
    this._snackBar.openFromComponent(SnackbarAlertComponent,
      {
        data: {
          message,
          link
        },
        ...this._alertConfig
      })
  }

  public error(message?: string, config?: MatSnackBarConfig): void {
    const errorMessage = message ?? (this._errorMessage.length ? this._errorMessage : this._translocoService.translate<string>('PRODUCTS.INFO_TAB.SNACKBAR.FORM.SAVE_FAILURE'));
   
    if (!this.snackBarList.length) {
      this.snackBarRef = this._snackBar.open(errorMessage, this.okButtonMessage, config ? config : this._errorConfig);
    }
    this.snackBarList.push({message: errorMessage})
    this.next();
  }

  private _initDefaultSnackbarMessages(): void {
    this._successMessage = this._translocoService.translate<string>('PRODUCTS.INFO_TAB.SNACKBAR.FORM.SAVE_SUCCESS');

    this.okButtonMessage = this._translocoService.translate<string>('PRODUCTS.INFO_TAB.SNACKBAR.OK_BUTTON');

    this._errorMessage = this._translocoService.translate<string>('PRODUCTS.INFO_TAB.SNACKBAR.FORM.SAVE_FAILURE');
  }
}
