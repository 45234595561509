import { BehaviorSubject, Observable } from 'rxjs';

import { Component } from '@angular/core';

import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pagination-component',
  template: '',
})
export class PaginationComponent {
  protected _pageSize$ = new BehaviorSubject<number>(0);
  protected _pageIndex$ = new BehaviorSubject<number>(0);

  public get pageSize$(): Observable<number> {
    return this._pageSize$.asObservable();
  }

  public get pageIndex$(): Observable<number> {
    return this._pageIndex$.asObservable();
  }

  constructor() {}

  public onPageChange(pageChanged: PageEvent): void {
    const page = Number(pageChanged.pageIndex) + 1;
    const limit = Number(pageChanged.pageSize);

    this._pageSize$.next(limit);
    this._pageIndex$.next(page);
  }
}
