import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode,
} from '@angular/common/http';

import { LocalStorageService } from '@app/shared';

import { IToken } from '../../../auth/interfaces/credentials.interface';
import { AuthService } from '../../../auth/services/auth.service';

@Injectable()
export class KeycloakInterceptor implements HttpInterceptor {

  constructor(
    private readonly _authService: AuthService,
    private readonly _localStorageService: LocalStorageService,
  ) {}

  public intercept(
    request: HttpRequest<never>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const credentials = this._localStorageService.get<IToken>('credentials');

    // if this is auth request - not handle it
    if (/\/auth\//i.test(request.url) && !/token/i.test(request.url)) {
      return next.handle(request);
    }

    if (credentials?.access_token) {
      request = this._attachAccessToken(request, credentials.access_token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          this._authService.logout();
        }
        return throwError(error);
      }),
    );
  }

  private _attachAccessToken(
    request: HttpRequest<never>,
    token: string,
  ): HttpRequest<never> {
    return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
  }
}
