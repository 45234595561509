import { ActivatedRouteSnapshot, Data, Params, Route } from '@angular/router';
import { Nullable } from '@app/shared';

export enum RouteRoles {
  read,
  create = 1,
  edit,
  editAndDelete
}

export type RouteData = {
  sidebar: boolean,
  entityTypes: Array<string>,
  routeRole: RouteRoles,
  editableSource: boolean,
};

export class RouteDataObj {

  static pluckBySnapshot(snapshot: Nullable<ActivatedRouteSnapshot>): RouteDataObj {
    return RouteDataObj.pluckByData((snapshot?.firstChild ?? snapshot)?.data || {});
  }

  static pluckByRoute(route: Nullable<Route>): RouteDataObj {
    return RouteDataObj.pluckByData(route?.data || {});
  }

  static pluckByData(data: Nullable<Data>): RouteDataObj {
    const {routeData, editableSource} = data || {};
    let {routeRole, entityTypes, sidebar} = routeData || {};
    if (routeRole === RouteRoles.edit && editableSource) {
      routeRole = RouteRoles.editAndDelete;
    }
    return new RouteDataObj({routeRole, entityTypes, sidebar, editableSource});
  }

  constructor(private readonly _data: RouteData) {
  }

  get routeData(): RouteData {
    return this._data;
  }

  private get routeRole(): RouteData['routeRole'] {
    return this._data.routeRole ?? RouteRoles.read;
  }

  private get entityTypes(): RouteData['entityTypes'] {
    return this._data.entityTypes ?? [];
  }

  public get hasRouteSidebar(): boolean {
    return Boolean(this._data.sidebar);
  }

  public get hasEditableSource(): boolean {
    return Boolean(this._data.editableSource);
  }

  public get isRouteForCreate(): boolean {
    return RouteRoles.create === this.routeRole;
  }

  public get isRouteForEdit(): boolean {
    return ([RouteRoles.edit, RouteRoles.editAndDelete] as Array<RouteRoles>).includes(this.routeRole);
  }

  public get isRouteForDelete(): boolean {
    return RouteRoles.editAndDelete === this.routeRole;
  }

  public isRouteForEntityType(type: string): boolean {
    return this.entityTypes.includes(type);
  }
}

/** @deprecated */
export function isRouteForCreate(snapshot: Nullable<ActivatedRouteSnapshot>): boolean {
  return RouteDataObj.pluckBySnapshot(snapshot).isRouteForCreate;
}
/** @deprecated */
export function isRouteForEdit(snapshot: Nullable<ActivatedRouteSnapshot>): boolean {
  return RouteDataObj.pluckBySnapshot(snapshot).isRouteForEdit;
}

export function pluckRouteEntity<R>(snapshot: Nullable<ActivatedRouteSnapshot>): Nullable<R> {
  const {entity} = (snapshot?.firstChild ?? snapshot)?.data || {};
  return entity ?? null;
}

export function pluckRouteDataParam<K extends keyof Params>(snapshot: Nullable<ActivatedRouteSnapshot>, key: K): Nullable<Params[K]> {
  const data = (snapshot?.firstChild ?? snapshot)?.data || {};
  const i = Object.keys(data).findIndex((atr) => key === atr);
  return (i > -1) ? Object.values(data)[i] : null;
}

export function pluckRouteParam<K extends keyof Params>(snapshot: Nullable<ActivatedRouteSnapshot>, key: K): Nullable<Params[K]> {
  const params: Params = (snapshot?.params || <Params>{});
  const i = Object.keys(params).findIndex((atr) => key === atr);
  return (i > -1) ? Object.values(params)[i] : null;
}

export function pluckQueryParams(snapshot: Nullable<ActivatedRouteSnapshot>, exclude: Array<keyof Params> = [], attach: Params = {}): Readonly<Params> {
  const queryParams = snapshot?.queryParams ?? {};
  let out: Params = {};
  Object.entries(queryParams).forEach(([key, val]) => {
    if (!exclude.includes(key)) {
      out[key] = val;
    }
  });
  Object.entries(attach).forEach(([key, val]) => {
    out[key] = val;
  });
  return out as Readonly<Params>;
}

export function pluckQueryParam<K extends keyof Params>(snapshot: Nullable<ActivatedRouteSnapshot>, key: K): Nullable<Params[K]> {
  const params: Params = (snapshot?.queryParams || <Params>{});
  const i = Object.keys(params).findIndex((atr) => key === atr);
  return (i > -1) ? Object.values(params)[i] : null;
}
