export const appDefaultConfig = {
  "ng.buildModeProduction": false, // don't change, false only for this file and true in prod.environment.ts

  "env.name": "localhost",                                        // x000 | условное имя стенда  (dev | stage | prod | localhost) | default: localhost
  "env.isProduction": false,                                      // x001 | required as true in prod/config.json only
  "env.browser.debug.enable": false,                              // x010 | false - clear console

  "gateway.browser.ssl": true,                                    // x010 |
  "gateway.upload.ssl": true,                                     // x010 |

  "sentry.browser.enable": true,                                  // x010 |
  "sentry.browser.breadcrumbs.console": true,                     // x010 |
  "sentry.browser.dsn": "",                                       // x003 |

  "app.name": "adm",                                              // x003 | header X-App-Platform
  "app.version": "unknown",                                       // x003 | header X-App-Version

  "app.google.maps.enable": true,                                 // x010 | google maps secure key
  "app.google.maps.key": "",                                      // x002 | google maps secure key
  "app.google.maps.location.latitude": 50.439385,                 // x010 | google maps default location
  "app.google.maps.location.longitude": 30.528439,                // x010 | google maps default location

  "http.browser.debug.enable": false,                             // x010 | enable debug http for browser
  "http.request.timeout": 30000,                                  // x010 | время ожидания запроса
  "http.request.withoutCache.enable": true,                       // x010 | header Cache-Control:no-cache;

  "app.resize.debounce": 1000,                                    // x010 | resize detection interval

  "app.product.uploadImage.size": 5,                              // x010 | product image max size mb
  "app.product.uploadImage.height": 2500,                         // x010 | product image max height px
  "app.product.uploadImage.width": 2500                           // x010 | product image max width px

}
