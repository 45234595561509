/** localstorage key names */
export const DELIVERY_COURIERS_FILTER_QUERY_STRING_KEY = 'delivery-couriers-list-query-string';
export const CREDENTIALS_KEY = 'credentials';
export const APPLICATION_TITLE = 'application-title';
export const OMS_FILTER_QUERY_STRING_KEY = 'oms-list-query-string';
export const ORGANISATION_KEY = 'organization';
export const PARTNER_KEY = 'partner';
export const PRODUCT_VIEW_TYPE = 'is-card-view';
export const PRODUCT_FILTER_QUERY_STRING_KEY = 'product-list-query-string';
export const CRIPS_WEBSITE_ID = '37087668-9053-4699-95ff-6836e2f66caf';
