import { OrderStatuses } from '@app/shared';

export function normalizeOrderStatus(status: string): string {
  let normalizedOrderStatus = 'SHARED.ORDER_STATUS.DEFAULT';

  switch (status) {
    case OrderStatuses.NEW:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.NEW';
      break;

    case OrderStatuses.PAYMENT_AWAITING:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.PAYMENT_AWAITING';
      break;

    case OrderStatuses.READY_FOR_DELIVERY:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.READY_FOR_DELIVERY';
      break;

    case OrderStatuses.IN_PROGRESS:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.IN_PROGRESS';
      break;

    case OrderStatuses.IN_DELIVERY:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.IN_DELIVERY';
      break;

    case OrderStatuses.DELIVERED:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.DELIVERED';
      break;

    case OrderStatuses.CANCELLED:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.CANCELLED';
      break;

    case OrderStatuses.CONFIRMATION_AWAITING:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.CONFIRMATION_AWAITING';
      break;
    case OrderStatuses.INRETURN:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.IN_RETURN';
      break;
    case OrderStatuses.RETURNED:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.RETURNED';
      break;
    case OrderStatuses.FITTING:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.FITTING';
      break;

    case OrderStatuses.ALL:
      normalizedOrderStatus = 'SHARED.ORDER_STATUS.ALL';
      break;
  }

  return normalizedOrderStatus;
}
