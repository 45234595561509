import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OrganizationStorage } from '@app/shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  constructor(
    private readonly _titleService: Title,
    private readonly _organizationStorage: OrganizationStorage,
  ) {}

  ngOnInit(): void {
    const appTitle: string = this._organizationStorage.orgTitle;
    if (appTitle) {
      this._titleService.setTitle(appTitle)
    }
  }
}


