<div class="layout-container">
  <button
    (click)="toggle()"
    class="clear ml-2 w-11 d-flex text-white align-items-center h-11"
  >
    <svg-util
      class="mt-2 ml-1"
      [path]="'toggle.svg#toggle.svg'"
      [viewBox]="'0 0 18 18'"
      [width]="'18'"
      [height]="'18'"
    ></svg-util>
  </button>
  <div class="d-flex ml-10 justify-space-between w-full">
    <div class="d-flex align-items-center">
      <p class="h-full layout-ttl d-flex align-items-center">
        <svg-util
          [path]="'title.svg#title-svg'"
          [viewBox]="'0 0 124 29'"
          [width]="'123'"
          [height]="'29'"
        ></svg-util>
      </p>
    </div>

    <alert-mute></alert-mute>
  </div>
</div>