import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AlertMuteComponent } from './alert-mute/alert-mute.component';
import { SharedModule } from '@app/shared/shared.module';

const MATERIAL = [
  MatTabsModule,
  MatIconModule,
  MatMenuModule,
];

@NgModule({
  declarations: [
    LayoutComponent,
    AlertMuteComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MATERIAL,
    TranslocoModule,
  ],
  exports: [
    LayoutComponent,
  ]
})
export class LayoutModule { }
