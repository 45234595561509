import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import {
  API_GATEWAY_URL,
  Entities,
  EntityCreate,
  EntityPut,
  ID,
  TypeKitchen,
  TypeResponseData,
  TypeResponsePaginated,
  TypeUserCredentials,
  TypeCourier,
  TypeResponseFilters,
  IEstimationInterface,
  ITimeEstimations,
  TypeCouriersFilters,
  TypeIntegrationsDeliveryFilters,
  TypeIntegrationConfig,
  EntityPath,
} from '@app/shared';
import { Observable, of } from 'rxjs';
import {TypeVenue} from "@app/shared/types/venue.type";

@Injectable({
  providedIn: 'root'
})
export class DeliveryRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/delivery';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  // [USE] GET    /v1/admin/delivery/ratings Получение Списка рейтинга todo: pagination is empty
  public getRatings(options?: any): Observable<TypeResponsePaginated<Entities<TypeVenue>>> {
    const {page, limit} = options
    const filter: {orderId?: string, courierId?: string, venueId?: string} = {};
    if (options?.orderId) {
      filter['orderId'] = options.orderId;
    }
    if (options?.courierId) {
      filter['courierId'] = options.courierId;
    }
    if (options?.venueId) {
      filter['venueId'] = options.venueId;
    }
    const params: RepositoryParams = this.matchParamsAsFilter({page, limit}, filter, {skipEmpty: true});  
    return this.get<TypeResponsePaginated<Entities<TypeVenue>>>('ratings', params); //Verify params is not supported
  }

  //TODO fix any types
  public addRatings(options: any): Observable<TypeResponseData<any>> {
    return this.post<TypeResponseData<any>, any>(`ratings`, options);
  }

  //[USE] GET    /v1/admin/delivery/venues Получение Списка локаций [DOC] todo: pagination is empty
  public getVenues(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeVenue>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeVenue>>>('venues', params); //Verify params is not supported
  }

  //[USE] GET    /v1/admin/delivery/venues/{venueId} Получение локации
  public getVenue(venueId: ID<TypeVenue>): Observable<TypeResponseData<TypeVenue>> {
    return this.get<TypeResponseData<TypeVenue>>(`venues/${venueId}`);
  }

  //[USE] POST   /v1/admin/delivery/venues Создание локации
  public createVenue(venue: EntityCreate<TypeVenue>): Observable<TypeResponseData<TypeVenue>> {
    return this.post<TypeResponseData<TypeVenue>, EntityCreate<TypeVenue>>('venues', venue);
  }

  //[USE] PUT    /v1/admin/delivery/venues/{venueId} Редактирование локации
  public updateVenue(venue: EntityPut<TypeVenue>): Observable<TypeResponseData<TypeVenue>> {
    const {id: venueId} = venue;
    return this.put<TypeResponseData<TypeVenue>, EntityPut<TypeVenue>>(`venues/${venueId}`, venue);
  }

  // DEPRECATED [USE] GET    /v1/admin/delivery/kitchens Получение Списка кухонь [DOC] todo: pagination is empty
  public getKitchens(options?: TypeResponseFilters): Observable<TypeResponsePaginated<Entities<TypeKitchen>>> {
    const {page, limit} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.get<TypeResponsePaginated<Entities<TypeKitchen>>>('kitchens', params); //Verify params is not supported
  }

  // DEPRECATED [USE] GET    /v1/admin/delivery/kitchens/{kitchenId} Получение кухни
  public getKitchen(kitchenId: ID<TypeKitchen>): Observable<TypeResponseData<TypeKitchen>> {
    return this.get<TypeResponseData<TypeKitchen>>(`kitchens/${kitchenId}`);
  }

  //[USE] POST   /v1/admin/delivery/couriers Создание курьера
  public createCourier(courier: EntityCreate<TypeCourier>): Observable<TypeResponseData<TypeCourier>> {
    return this.post<TypeResponseData<TypeCourier>, EntityCreate<TypeCourier>>('couriers', courier);
  }

  //[USE] GET    /v1/admin/delivery/couriers Получение списка курьеров
  public getCouriers(options: TypeCouriersFilters): Observable<TypeResponsePaginated<Entities<TypeCourier>>> {
    const {page, limit, venueIds, statuses, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    if (venueIds?.length) {
      venueIds.forEach((v, i) => {
        a[`filter[venueIds][${i}]`] = String(v);
      })
    }
    if (statuses?.length) {
      statuses.forEach((v, i) => {
        a[`filter[statuses][${i}]`] = String(v);
      })
    }
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams); //filter[venueIds] filter[statuses]
    return this.get<TypeResponsePaginated<Entities<TypeCourier>>>('couriers', params); //Verify paginated
  }

  //[USE] POST   /v1/admin/delivery/couriers-batch Получение списка курьеров
  public couriersBatch(options: TypeCouriersFilters): Observable<TypeResponsePaginated<Entities<TypeCourier>>> {
    const {page, limit, ...filterParams} = options || {}, params: RepositoryParams = page && limit ? {page, limit} : {};
    return this.post<TypeResponsePaginated<Entities<TypeCourier>>, any>('couriers-batch', {filter: filterParams}, params);
  }

  //[USE] PUT    /v1/admin/delivery/couriers/{courierId} Редактирование курьера
  public updateCourier(courier: EntityPut<TypeCourier>): Observable<TypeResponseData<TypeCourier>> {
    const {id: courierId} = courier;
    return this.put<TypeResponseData<TypeCourier>, EntityPut<TypeCourier>>(`couriers/${courierId}`, courier);
  }

  //[USE] GET    /v1/admin/delivery/couriers/{courierId} Получение курьера
  public getCourier(courierId: ID<TypeCourier>): Observable<TypeResponseData<TypeCourier>> {
    return this.get<TypeResponseData<TypeCourier>>(`couriers/${courierId}`);
  }

  //[USE] GET    /v1/admin/delivery/time-estimation-constants/{kitchenId} Получение значений времен доставки кухни
  public getDeliveryTime(kitchenId: string | null = null): Observable<TypeResponseData<IEstimationInterface>> {
    return this.get<TypeResponseData<IEstimationInterface>>(`time-estimation-constants/${kitchenId}`);
  }

  //[USE] PUT    /v1/admin/delivery/time-estimation-constants/{kitchenId} Редактирование значений времен доставки кухни
  public setDeliveryTime(kitchenId: string | null, obj: ITimeEstimations): Observable<TypeResponseData<IEstimationInterface>> {
    return this.put<TypeResponseData<IEstimationInterface>, ITimeEstimations>(`time-estimation-constants/${kitchenId}`, obj);
  }

  //[NOT] PUT    /v1/admin/delivery/kitchens/{kitchenId}/enable Включить кухню
  //[NOT] PUT    /v1/admin/delivery/kitchens/{kitchenId}/disable Выключить кухню
  //[NOT] POST   /v1/admin/delivery/couriers/batch-register Batch Создание курьеров
  //[NOT] DELETE /v1/admin/delivery/couriers/{courierId} Удаление курьера
  //[NOT] GET    /v1/admin/delivery/kitchens/{kitchenId}/export-polygon-groups Выгрузка групп полигонов кухни

  //[USE] GET /v1/admin/delivery/configs/{type} Информация о конфигурации
  public getDeliveryConfigs(options: TypeIntegrationsDeliveryFilters): Observable<TypeResponseData<Entities<TypeIntegrationConfig>>> {
    const {page, limit, type, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams, {skipEmpty: false});
    return this.get<TypeResponseData<Entities<TypeIntegrationConfig>>>(`configs/${type}`, params);
  }

  //[NOT] GET /v1/admin/delivery/config/{configId} Получение конфига по ID
  public getKitchenConfigs(kitchenId: string): Observable<TypeResponseData<TypeIntegrationConfig>> {
    return this.get<TypeResponseData<TypeIntegrationConfig>>(`config/${kitchenId}`);
  }

  //[USE] PATCH /v1/admin/delivery/config/{configId} Редактирование конфигурации
  public updateConfigs(config: EntityPath<TypeIntegrationConfig>): Observable<TypeResponseData<TypeIntegrationConfig>> {
    return this.patch<TypeResponseData<TypeIntegrationConfig>, EntityPath<TypeIntegrationConfig>>(`config/${config.id}`, config);
  }
}
