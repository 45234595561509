import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { API_GATEWAY_URL } from '@app/shared';

export abstract class ApiGatewayInterceptor implements HttpInterceptor {

  protected readonly apiGatewayUrl: string = inject(API_GATEWAY_URL);

  protected abstract _intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.apiGatewayUrl) && this._intercept) {
      return this._intercept(req, next);
    }
    return next.handle(req);
  }
}
